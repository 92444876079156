import postcodes from './postcodes.json';

export interface LocationFactors {
  postcode: string;
  noxCost: number;
  pm26Cost: number;
  avgSpeed: number;
  avgBusSpeed: number;
}

function binarySearch(
  arr: [string, number, number, number, number][],
  el: string
): LocationFactors | null {
  let m = 0;
  let n = arr.length - 1;

  while (m <= n) {
    var k = (n + m) >> 1;

    const target = arr[k];

    if (target[0] < el) {
      m = k + 1;
    } else if (target[0] > el) {
      n = k - 1;
    } else {
      return {
        postcode: target[0],
        noxCost: target[1],
        pm26Cost: target[2],
        avgSpeed: target[3],
        avgBusSpeed: target[4],
      };
    }
  }
  return null;
}

let lastSeen: LocationFactors | null = null;

export function locationFactors(postcode: string) {
  if (!lastSeen || lastSeen.postcode !== postcode) {
    lastSeen = binarySearch(postcodes as any, postcode);
  }

  return lastSeen;
}
