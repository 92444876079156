import styled from 'styled-components/macro';
import { Button } from '../Button';

export const QuizFormWrapper = styled.form`
  flex: 1;
  position: relative;
  margin-bottom: 3.2rem;

  .prev {
    margin-top: 2.4rem;
  }
`;

export const QuizQuestionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NextButton = styled(Button)`
  display: block;
  margin: 3.2rem auto 0 auto;
  width: 100%;
  max-width: 35rem;
`;

export const Footnote = styled.p`
  margin-top: 3.2rem;
  font-size: 1.6rem;
`;
