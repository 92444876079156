import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { breakpointsMin } from '../../styles';
import { bodyStyles } from '../../styles/text';

const Links = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-bottom: 1.2rem;
  align-self: center;

  li:not(:last-child) {
    margin-right: 1.2rem;
  }
`;

const FooterWrapper = styled.div`
  padding: 5rem 0;
  ${bodyStyles.sm};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  color: var(--apc-cloud);
  text-align: center;

  p {
    margin-bottom: 1.6rem;
  }

  ${breakpointsMin.sm} {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    text-align: left;

    ${Links} {
      align-self: flex-end;
    }
  }
`;

const Description = styled.div`
  max-width: 50rem;
`;

export const Footer: FC = () => {
  return (
    <FooterWrapper>
      <Description>
        <p>The Air Pollution Calculator is brought to you by Global Action Plan.</p>
        <p>
          Charity registered in England and Wales number 1026148, and in Scotland number SC041260. ©
          2020
        </p>
      </Description>
      <Links>
        <li>
          <Link to="/methodology">Methodology</Link>
        </li>
        <li>
          <a
            href="https://www.cleanairhub.org.uk/terms-and-conditions"
            target="_blank"
            rel="noreferrer"
          >
            T and Cs
          </a>
        </li>
        <li>
          <a href="mailto:cleanerair@globalactionplan.org.uk">Contact</a>
        </li>
      </Links>
    </FooterWrapper>
  );
};
