import styled from 'styled-components/macro';

const Wrapper = styled.div`
  padding: 14.4rem 0;
  display: grid;
  place-items: center;
`;

export const ResultsSpacer = () => {
  return (
    <Wrapper>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" className="marker">
        <path
          d="M21.7656 10.6719L21.4375 10.3438C21.2031 10.1094 20.875 10.1094 20.6406 10.3438L12.2969 18.6875V1.0625C12.2969 0.78125 12.0156 0.5 11.7344 0.5H11.2656C10.9375 0.5 10.7031 0.78125 10.7031 1.0625V18.6875L2.3125 10.3438C2.07812 10.1094 1.75 10.1094 1.51562 10.3438L1.1875 10.6719C0.953125 10.9062 0.953125 11.2344 1.1875 11.4688L11.0781 21.3594C11.3125 21.5938 11.6406 21.5938 11.875 21.3594L21.7656 11.4688C22 11.2344 22 10.9062 21.7656 10.6719Z"
          fill="#A7A7A7"
        />
      </svg>
    </Wrapper>
  );
};
