import styled from 'styled-components/macro';
import { bodyStyles, titleStyles } from '../../styles/text';

export const TextLabel = styled.label`
  margin-bottom: 3.2rem;
  ${titleStyles.md};
`;

export const TextInput = styled.input.attrs({
  type: 'text',
})`
  border: 2px solid rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  background-color: transparent;
  padding: 1.2rem 1.6rem;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  align-self: center;
  width: 100%;
  ${bodyStyles.lg};

  &:focus {
    outline: none;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
