import { VFC } from 'react';
import styled from 'styled-components/macro';
import { breakpointsMin } from '../../styles';
import { bodyStyles, titleStyles } from '../../styles/text';

const overages = ['two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];

const underages = [
  '10% lower', // 90%
  '20% lower', // 80%
  'one third lower', // 70%
  '60% lower', // 60%
  '50% lower', // 50%
  '60% lower', // 40%
  'two thirds lower', // 30%
  '80% lower', // 20%
  '90% lower', // 10%
  '90% lower', // 10%
];

const Wrapper = styled.div`
  b {
    font-weight: inherit;
    color: var(--apc-yellow);
  }
`;

const Message = styled.div`
  ${titleStyles.lg};

  ${breakpointsMin.md} {
    ${titleStyles.xl};
  }
`;

const Target = styled.p`
  ${bodyStyles.md};
  margin: 2.4rem 0;

  b {
    font-weight: inherit;
    color: var(--apc-yellow);
  }
`;

export const Description: VFC<{ count: number; target: number; className?: string }> = ({
  count,
  className,
  target,
}) => {
  let message = (
    <>
      Congratulations - you create a <b>low level</b> of air pollution - please continue the good
      work!
    </>
  );

  if (count > 0) {
    let quantity = <b>equal to</b>;

    if (count > 100) {
      const value = count / 100;

      if (value >= 2) {
        let overage = overages[Math.round(value) - 2];

        if (!overage) {
          overage = `${Math.round(value)}`;
        }

        quantity = (
          <>
            <b>{overage} times higher</b> than
          </>
        );
      } else if (value >= 1.1) {
        quantity = (
          <>
            <b>{Math.round((value - 1) * 10) * 10}% higher</b> than
          </>
        );
      }
    } else if (count < 100) {
      const value = Math.round((100 - count) / 10);

      if (value > 0) {
        quantity = (
          <>
            <b>{underages[value - 1]}</b> than
          </>
        );
      }
    }

    message = <>Your personal pollution footprint is about {quantity} the UK national average</>;
  }

  const targetText =
    target === 0 ? (
      <>
        Congratulations - you create a low level of air pollution - please{' '}
        <b>continue the good work</b> and <b>help raise awareness</b>
      </>
    ) : target === 0.5 ? (
      <>
        Your score is above national average. Could you commit to <b>reduce your score by half</b>{' '}
        over the next year?
      </>
    ) : (
      <>
        Your score is lower than national average, but there are still ways you can improve your
        footprint. Could you commit to <b>reduce your score by a quarter</b> over the next year?
      </>
    );

  return (
    <Wrapper className={className}>
      <Message>{message}</Message>
      <Target>{targetText}</Target>
    </Wrapper>
  );
};
