import styled, { css } from 'styled-components/macro';
import { colors } from '../../../colors';

export interface PledgeChartProps {
  orientation: 'h' | 'v';
  target: number;
  pledged: number;
  className?: string;
}

const PledgeChartColumn = styled.div``;

const PledgeChartWrapper = styled.div<{ orientation: 'h' | 'v' }>`
  display: grid;

  ${({ orientation }) =>
    orientation === 'h'
      ? css`
          grid-template: auto / repeat(3, minmax(0, 1fr));
          gap: 2.4rem;

          ${PledgeChartColumn} {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            justify-content: flex-end;
          }

          .block {
            height: calc(var(--_value) * 10rem);
            align-self: stretch;
          }

          .value {
            display: block;
          }

          .label {
            text-align: center;
          }
        `
      : css`
          grid-template: repeat(3, auto) / auto 1fr;
          gap: 1.6rem;

          ${PledgeChartColumn} {
            flex-direction: row;
            display: contents;
          }

          .block {
            width: calc(var(--_value) * 100%);
            height: 4rem;
          }

          .label {
            align-self: center;
          }

          .value {
            margin-left: 0.8rem;
          }
        `}
`;

const PledgeChart = ({ orientation, target, pledged, className }: PledgeChartProps) => {
  const max = Math.max(target, 100, pledged);

  const targetValue = target / max;
  const averageValue = 100 / max;
  const pledgedValue = pledged / max;

  return (
    <PledgeChartWrapper orientation={orientation} className={className}>
      <PledgeChartColumn>
        <div className="label">
          Target<span className="value">{Math.round(target)}%</span>
        </div>
        <div className="block" style={{ '--_value': `${targetValue}`, background: colors.pink }}></div>
      </PledgeChartColumn>
      <PledgeChartColumn>
        <div className="label">National Average</div>
        <div className="block" style={{ '--_value': `${averageValue}`, background: colors.lightGrey }}></div>
      </PledgeChartColumn>
      <PledgeChartColumn>
        <div className="label">
          You<span className="value">{Math.round(pledged)}%</span>
        </div>
        <div className="block" style={{ '--_value': `${pledgedValue}`, background: colors.yellow }}></div>
      </PledgeChartColumn>
    </PledgeChartWrapper>
  );
};

export default PledgeChart;
