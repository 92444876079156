import { InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components/macro';

const Label = styled.label`
  display: inline-flex;
  gap: 1.2rem;
  align-items: center;
`;

const Input = styled.input`
  appearance: none;
  height: 2.1rem;
  width: 2.1rem;
  border: 2px solid var(--apc-white);
  border-radius: 0.4em;
  display: inline-grid;
  place-items: center;
  flex-shrink: 0;

  &:checked {
    background: var(--apc-yellow);
    border: 0;

    &::after {
      content: '';
      background-image: url('/images/check.svg');
      background-repeat: no-repeat;
      background-position: center;
      width: 1.6rem;
      height: 1.6rem;
    }
  }
`;

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode;
  className?: string;
}

const Checkbox = ({ label, className, ...props }: CheckboxProps) => {
  return label ? (
    <Label className={className}>
      <Input type="checkbox" {...props} />
      {label}
    </Label>
  ) : (
    <Input type="checkbox" className={className} {...props} />
  );
};

export default Checkbox;
