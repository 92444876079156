import { useMemo } from 'react';
import { CalcBreakdown } from '../../calc/types';
import { colors } from '../../colors';

type PieData = Array<{
  title: string;
  value: number;
  color: string;
}>;

export function usePie(breakdown: CalcBreakdown): PieData {
  return useMemo(() => {
    const pieData: PieData = [];

    if (breakdown.commuting > 0) {
      pieData.push({
        title: 'Commuting',
        value: Math.round(breakdown.commuting * 100),
        color: colors.pink,
      });
    }

    if (breakdown.leisure > 0) {
      pieData.push({
        title: 'Other travel',
        value: Math.round(breakdown.leisure * 100),
        color: colors.blue,
      });
    }

    if (breakdown.domestic > 0) {
      pieData.push({
        title: 'Domestic Heating & Electricity Consumption',
        value: Math.round(breakdown.domestic * 100),
        color: colors.yellow,
      });
    }

    if (breakdown.delivery > 0) {
      pieData.push({
        title: 'Delivery',
        value: Math.round(breakdown.delivery * 100),
        color: colors.white,
      });
    }

    return pieData;
  }, [breakdown]);
}
