import { FormState, QuestionId } from './pages/Quiz.types';
import { createContext, useContext, FC, useReducer, useCallback, useMemo } from 'react';
import { questions } from './questions';
import { locationFactors } from './calc/location';
import { CalcResults, CalcState, ExtraPledge, Pledge, PledgeState, QuizPledge } from './calc/types';
import { calcResults } from './calc';
import { calcPledges } from './calc/pledges';
import { produce } from 'immer';

interface SetAnswersAction {
  type: 'set-answers';
  payload: FormState;
}

interface SetQuestionAction {
  type: 'set-question';
  payload: number;
}

interface ResetAction {
  type: 'reset';
}

interface TogglePledgeAction {
  type: 'toggle-pledge';
  payload: QuizPledge | ExtraPledge;
}

type Actions = SetAnswersAction | SetQuestionAction | ResetAction | TogglePledgeAction;

export const blankState: CalcState = {
  questionId: QuestionId.Q1Postcode,
  answers: Object.fromEntries(
    questions.map((q) => {
      switch (q.type) {
        case 'message':
          return [q.id, true];
        case 'number':
          return [q.id, 0];
        case 'postcode':
          return [q.id, ''];
        default:
          return [q.id, null];
      }
    })
  ) as FormState,
  validPostcode: false,
  activePledges: {},
};

function reducer(state: CalcState, action: Actions): CalcState {
  switch (action.type) {
    case 'set-answers': {
      const postcode = action.payload[QuestionId.Q1Postcode]?.toUpperCase();
      const location = postcode ? locationFactors(postcode) : null;

      return {
        ...state,
        answers: action.payload,
        validPostcode: location !== null,
      };
    }
    case 'toggle-pledge': {
      const pledge = action.payload;

      return produce(state, (state) => {
        if (pledge.pledgeGroup) {
          if (state.activePledges[pledge.pledgeGroup] === pledge.id) {
            delete state.activePledges[pledge.pledgeGroup];
          } else {
            state.activePledges[pledge.pledgeGroup] = pledge.id;
          }
        } else {
          if (state.activePledges[pledge.id]) {
            delete state.activePledges[pledge.id];

            if (pledge.id === 'commuteElec' || pledge.id === 'leisureElec') {
              delete state.activePledges['leisureElec'];
              delete state.activePledges['commuteElec'];
            }
          } else {
            state.activePledges[pledge.id] = pledge.id;

            if (pledge.id === 'commuteElec' || pledge.id === 'leisureElec') {
              state.activePledges['leisureElec'] = 'leisureElec';
              state.activePledges['commuteElec'] = 'commuteElec';
            }
          }
        }
      });
    }
    case 'set-question': {
      return {
        ...state,
        questionId: action.payload,
      };
    }
    case 'reset': {
      return blankState;
    }
  }
}

export const CalcContext = createContext<{
  state: CalcState;
  results: CalcResults | null;
  pledges: PledgeState | null;
  setAnswers: (answers: FormState) => void;
  setQuestion: (question: number) => void;
  togglePledge: (pledge: Pledge) => void;
  reset: () => void;
}>({
  state: blankState,
  pledges: null,
  results: null,
  setAnswers: () => {},
  setQuestion: () => {},
  togglePledge: () => {},
  reset: () => {},
});

export const CalcProvider: FC<{ initialState?: Partial<CalcState> }> = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(reducer, { ...blankState, ...initialState });

  const results = useMemo(() => calcResults(state.answers), [state.answers]);

  const pledges = useMemo(
    () => calcPledges(state.answers, results, state.activePledges),
    [state.answers, results, state.activePledges]
  );

  const setAnswers = useCallback(
    (answers: FormState) =>
      dispatch({
        type: 'set-answers',
        payload: answers,
      }),
    [dispatch]
  );

  const setQuestion = useCallback(
    (question: number) => {
      dispatch({
        type: 'set-question',
        payload: question,
      });
    },
    [dispatch]
  );

  const reset = useCallback(() => {
    dispatch({
      type: 'reset',
    });
  }, [dispatch]);

  const togglePledge = useCallback(
    (pledge: QuizPledge | ExtraPledge) => {
      dispatch({
        type: 'toggle-pledge',
        payload: pledge,
      });
    },
    [dispatch]
  );

  return (
    <CalcContext.Provider value={{ state, results, pledges, setAnswers, setQuestion, togglePledge, reset }}>
      {children}
    </CalcContext.Provider>
  );
};

export const useCalc = () => useContext(CalcContext);

export const useResults = () => useCalc().results;

export const useQuestion = () => {
  const {
    state: { questionId },
  } = useCalc();

  return questions.find((q) => q.id === questionId);
};
