import { Question, QuestionId, FormFilter, Option, QuestionInfo } from './pages/Quiz.types';
import { ReactComponent as BusImage } from './images/bus.svg';
import { ReactComponent as CarsImage } from './images/cars.svg';
import { ReactComponent as FamilyImage } from './images/family.svg';
import { ReactComponent as MotorbikeImage } from './images/motorbike.svg';
import { ReactComponent as TransportMethodsImage } from './images/transport-methods.svg';
import { ReactComponent as FireplaceImage } from './images/fireplace.svg';
import * as React from 'react';
import styled from 'styled-components/macro';
import { CarTech, CarType } from './calc/cars';
import { BikeTech, BikeType } from './calc/motorbike';
import { ResidenceType } from './calc/residence';
import { BoilerFuel, StoveType } from './calc/heating';
import { bodyStyles } from './styles/text';

const typer = <T extends string>(x: Record<T, FormFilter>): Record<T, FormFilter> => x;

export enum TransportType {
  Car = 'Car',
  Bus = 'Bus',
  Motorbike = 'Motorbike',
  Train = 'Train',
  WalkCycle = 'WalkCycle',
  Dont = 'Dont',
}

const filters = typer({
  commuteByCar: ({ [QuestionId.Q2Commute]: commute }) => commute === TransportType.Car,
  commuteByBus: ({ [QuestionId.Q2Commute]: commute }) => commute === TransportType.Bus,
  commuteByMotorbike: ({ [QuestionId.Q2Commute]: commute }) => commute === TransportType.Motorbike,
  nonCommuteByCar: ({ [QuestionId.Q11NonCommuteCar]: nonCommute }) => nonCommute === true,
  nonCommuteByBus: ({ [QuestionId.Q16NonCommuteBus]: nonCommute }) => nonCommute === true,
  hasWoodBurner: ({ [QuestionId.Q20WoodBurner]: woodBurner }) => woodBurner === true,
  onlineDelivery: ({ [QuestionId.Q26OnlinePurchases]: online }) => online === true,
  onlineGrocery: ({ [QuestionId.Q28OnlineGroceries]: online }) => online === true,
  gasBoiler: ({ [QuestionId.Q23GasBoiler]: boiler }) => boiler === true,
});

const carTypes: Option[] = [
  { label: `Diesel`, value: CarType.Diesel },
  { label: `Petrol`, value: CarType.Petrol },
  { label: `Hybrid Petrol`, value: CarType.HybridPetrol },
  { label: `Electric`, value: CarType.Electric },
];

const yesNo: Option[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const carAges = [
  { label: `< 2 Years`, value: CarTech.Euro6D },
  { label: `3-6 Years`, value: CarTech.Euro6ABC },
  { label: `7-10 Years`, value: CarTech.Euro5 },
  { label: `11-15 years`, value: CarTech.Euro4 },
  { label: `15+ Years`, value: CarTech.Euro3 },
];

const bikeAges = [
  {
    label: '< 2 Years',
    value: BikeTech.Euro5,
  },
  {
    label: '3-5 Years',
    value: BikeTech.Euro4,
  },
  {
    label: '6+ Years',
    value: BikeTech.Euro3,
  },
];

const carTypeBlurb = `25% of all car journeys in the UK are under two miles. Many of these journeys could be taken on foot or bike – fewer car journeys will reduce air pollution and help you get your daily dose of exercise.`;

const InfoPara = styled.p`
  margin: 0 0 1.2rem 0;
`;

const InfoFootnote = styled.p`
  ${bodyStyles.sm};
  word-break: break-all;
`;

const carAgeBlurb = (
  <>
    <InfoPara>
      There are many sources of outdoor air pollution including road transport, energy generation,
      industry, open fires and stoves and agriculture. Road transport, including cars and delivery
      vehicles, is the main source of{' '}
      <a
        href="https://www.cleanairhub.org.uk/clean-air-information/the-basic-information#gases"
        target="_blank"
        rel="noreferrer"
      >
        Nitrogen Dioxide pollution
      </a>
      .
    </InfoPara>
    <InfoPara>
      Nitrogen Dioxide can affect our health. There is evidence that high levels of it can inflame
      the airways in our lungs and, over a long period of time, affect how well our lungs work.
      <sup>3</sup>
    </InfoPara>
    <InfoFootnote>
      <sup>3</sup>{' '}
      <a
        href="https://uk-air.defra.gov.uk/assets/documents/reports/aqeg/nd-summary.pdf"
        target="_blank"
        rel="noreferrer"
      >
        https://uk-air.defra.gov.uk/assets/documents/reports/aqeg/nd-summary.pdf
      </a>
    </InfoFootnote>
  </>
);

const carHoursBlurb = `The most polluted place to be on the roads is often inside a vehicle, as they gather pollution, car drivers can be exposed to twice as much air pollution as pedestrians or cyclists.`;

const carTravellersBlurb = `If you do have to drive, don’t idle, but turn off your engine, or ask the driver to turn off the engine, when your vehicle is stationary, and it is safe to do so.`;

const busBlurb = `Buses are an essential part of any modern transport network. Good bus services help tackle congestion in cities and stop rural communities being cut off. Some bus models can pollute so more modern buses are needed to help cut air pollution and reduce carbon emissions.`;

const didYouKnow = `Did you know?`;

const deliveryInfo1: QuestionInfo = {
  title: didYouKnow,
  image: TransportMethodsImage,
  body: (
    <>
      <InfoPara>
        How we get our online shopping delivered to us can be a big influence on how many polluting
        delivery vehicles drive down our roads each day. Did you know that diesel vans are one of
        the largest sources of pollution on our local streets?
      </InfoPara>
      <InfoPara>
        <a
          href="https://www.cleanairhub.org.uk/clean-air-information/what-can-i-do/what-decisions-can-i-make-when-i-m-shopping-to-reduce-air-pollution"
          target="_blank"
          rel="noreferrer"
        >
          Find out more
        </a>{' '}
        about the decisions you can make to reduce air pollution when shopping.
      </InfoPara>
    </>
  ),
};

const deliveryInfo2: QuestionInfo = {
  title: didYouKnow,
  image: TransportMethodsImage,
  body: (
    <>
      <InfoPara>
        Almost half of all people recognise home deliveries as a cause of increasing air pollution.
      </InfoPara>
      <InfoPara>
        During the last 12 months the purchase of groceries and hot food has increased by almost a
        third.
      </InfoPara>
      <InfoPara>
        <a
          href="https://www.cleanairhub.org.uk/clean-air-information/what-can-i-do/what-decisions-can-i-make-when-i-m-shopping-to-reduce-air-pollution"
          target="_blank"
          rel="noreferrer"
        >
          Find out more
        </a>{' '}
        about the decisions you can make to reduce air pollution when shopping.
      </InfoPara>
    </>
  ),
};

export const questions: Question[] = [
  {
    id: QuestionId.Q1Postcode,
    type: 'postcode',
    label: 'Please enter the first 3-4 characters of your postcode',
    info: {
      title: didYouKnow,
      body: (
        <>
          <InfoPara>
            Although air pollution can be harmful to everyone, some people are more affected because
            they live in a polluted area and are exposed to higher levels of air pollution. Big
            cities such as Manchester, London and Birmingham – experience greater pollution levels
            (particularly nitrogen dioxide).
          </InfoPara>
          <InfoPara>
            Learn more about air pollution &amp; health inequalities on the{' '}
            <a
              href="https://www.cleanairhub.org.uk/how-does-air-pollution-damage-my-health"
              target="_blank"
              rel="noreferrer"
            >
              Clean Air Hub.
            </a>
          </InfoPara>
        </>
      ),
      image: FamilyImage,
    },
  },
  {
    id: QuestionId.Q2Commute,
    type: 'options',
    label: 'How do you usually commute to work, college or school?',
    options: [
      { label: 'Car', value: TransportType.Car },
      { label: 'Bus', value: TransportType.Bus },
      { label: 'Motorbike', value: TransportType.Motorbike },
      { label: 'Train/Tube', value: TransportType.Train },
      { label: 'Walk/Cycle', value: TransportType.WalkCycle },
      { label: `I don't commute`, value: TransportType.Dont },
    ],
    info: {
      title: didYouKnow,
      body: (
        <>
          <InfoPara>
            Primary and nursery school children can be exposed to 15% more pollution compared to
            adults when walking on busy roads due to them being closer to exhaust fumes.<sup>1</sup>{' '}
            <sup>2</sup>
          </InfoPara>
          <InfoFootnote>
            <sup>1</sup>{' '}
            <a
              href="https://www.cleanairday.org.uk/news/toxic-school-run"
              target="_blank"
              rel="noreferrer"
            >
              Toxic School Run, Clean Air Day 2018
            </a>
          </InfoFootnote>
          <InfoFootnote>
            <sup>2</sup>{' '}
            <a
              href="https://www.research.ed.ac.uk/portal/files/27245553/Kenagy_etal_2016_AtmAQHealth_NO2_Child_vs_Adult_Height.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Greater nitrogen dioxide concentrations at child versus adult breathing heights close
              to urban main road kerbside Hannah S. Kenagy & Chun Lin & Hao Wu & Mathew R. Heal,
              2015
            </a>
          </InfoFootnote>
        </>
      ),
      image: TransportMethodsImage,
    },
  },
  {
    id: QuestionId.Q3CarType,
    type: 'options',
    label: 'What type of car do you have?',
    options: carTypes,
    filter: filters.commuteByCar,
    info: {
      image: CarsImage,
      title: didYouKnow,
      body: carTypeBlurb,
    },
  },
  {
    id: QuestionId.Q4CarAge,
    type: 'options',
    label: 'How old is your car?',
    subLabel: `If you know your car’s ‘Euro’ emissions standard, please select the corresponding option.`,
    options: carAges,
    filter: filters.commuteByCar,
    info: {
      image: CarsImage,
      title: didYouKnow,
      body: carAgeBlurb,
    },
  },
  {
    id: QuestionId.Q5CarHours,
    type: 'number',
    label: `On average what is the total number of hours you spend in your car for commuting each week?`,
    info: {
      image: CarsImage,
      title: didYouKnow,
      body: carHoursBlurb,
    },
    filter: filters.commuteByCar,
  },
  {
    id: QuestionId.Q6CarTravellers,
    type: 'number',
    label: `How many people usually travel with you to the same place of work, college or school?`,
    info: {
      image: CarsImage,
      title: didYouKnow,
      body: carTravellersBlurb,
    },
    filter: filters.commuteByCar,
  },
  {
    id: QuestionId.Q7BusHours,
    type: 'number',
    label: `How many hours do you usually spend on a bus for commuting each week?`,
    info: {
      image: BusImage,
      body: busBlurb,
    },
    filter: filters.commuteByBus,
  },
  {
    id: QuestionId.Q8MotorbikeType,
    type: 'options',
    label: `What is your motorbike’s engine type and size?`,
    info: {
      image: MotorbikeImage,
    },
    options: [
      { label: `2-stroke < 50cc`, value: BikeType.TwoStroke50 },
      { label: `2-stroke > 50cc`, value: BikeType.TwoStroke50Plus },
      { label: `4-stroke < 50cc`, value: BikeType.FourStroke50 },
      { label: `4-stroke < 250cc`, value: BikeType.FourStroke250 },
      { label: `4-stroke 250-750cc`, value: BikeType.FourStroke750 },
      { label: `4-stroke > 750cc`, value: BikeType.FourStroke750Plus },
      { label: 'Not known', value: BikeType.Unknown },
    ],
    filter: filters.commuteByMotorbike,
  },

  {
    id: QuestionId.Q9MotorbikeTech,
    type: 'options',
    label: `How old is your motorbike?`,
    info: {
      image: MotorbikeImage,
    },
    options: bikeAges,
    filter: filters.commuteByMotorbike,
  },

  {
    id: QuestionId.Q10MotorbikeHours,
    type: 'number',
    label: `How many hours do you usually spend on a motorbike for commuting per week?`,
    info: {
      image: MotorbikeImage,
    },
    filter: filters.commuteByMotorbike,
  },
  {
    id: QuestionId.Q11NonCommuteCar,
    type: 'options',
    label: `Outside of commuting, do you travel by car? (e.g. shopping, leisure, visiting friends/family)`,
    options: yesNo,
    info: {
      image: CarsImage,
    },
  },
  {
    id: QuestionId.Q12NonCommuteCarType,
    type: 'options',
    label: `What type of car do you have?`,
    options: carTypes,
    filter: filters.nonCommuteByCar,
    info: {
      image: CarsImage,
      title: didYouKnow,
      body: carTypeBlurb,
    },
  },
  {
    id: QuestionId.Q13NonCommuteCarAge,
    type: 'options',
    label: 'How old is your car?',
    options: carAges,
    filter: filters.nonCommuteByCar,
    info: {
      image: CarsImage,
      title: didYouKnow,
      body: carAgeBlurb,
    },
  },
  {
    id: QuestionId.Q14NonCommuteCarHours,
    type: 'number',
    label: `What is the total number of hours you usually spend in your car outside of commuting each week?`,
    info: {
      image: CarsImage,
      title: didYouKnow,
      body: carHoursBlurb,
    },
    filter: filters.nonCommuteByCar,
  },
  {
    id: QuestionId.Q15NonCommuteCarTravellers,
    type: 'number',
    label: `How many people are usually with you in the car at the same time?`,
    info: {
      image: CarsImage,
      title: didYouKnow,
      body: carTravellersBlurb,
    },
    filter: filters.nonCommuteByCar,
  },
  {
    id: QuestionId.Q16NonCommuteBus,
    type: 'options',
    label: `Outside of commuting, do you travel by bus?  (e.g. shopping, leisure, visiting friends/family)`,
    options: yesNo,
    info: {
      image: BusImage,
      title: didYouKnow,
      body: busBlurb,
    },
  },
  {
    id: QuestionId.Q17NonCommuteBusHours,
    type: 'number',
    label: `How many hours do you usually spend on a bus outside of commuting each week?`,
    info: {
      image: BusImage,
    },
    filter: filters.nonCommuteByBus,
  },
  {
    id: QuestionId.Q18ResidenceSize,
    type: 'options',
    label: 'What is the size of your residence?',
    info: {
      image: FireplaceImage,
    },
    options: [
      { label: 'Flat or Small house (1-2 Bedrooms, 1 Bathroom)', value: ResidenceType.Small },
      { label: 'Medium house (2-3 Bedrooms, 1 Bathroom)', value: ResidenceType.Medium },
      { label: 'Large house (4+ Bedrooms, 2+ Bathrooms)', value: ResidenceType.Large },
    ],
  },
  {
    id: QuestionId.Q19Cohabitants,
    type: 'number',
    label: 'How many adults do you live with?',
    info: {
      image: FireplaceImage,
    },
  },
  {
    id: QuestionId.Q20WoodBurner,
    type: 'options',
    label: 'Do you use a wood-burning stove or open fireplace at home?',
    options: yesNo,
    info: {
      title: didYouKnow,
      body: (
        <>
          Household burning, such as the use of wood stoves and open fires, is estimated to be the
          biggest contributor to{' '}
          <a
            href="https://www.cleanairhub.org.uk/clean-air-information/the-basic-information#particle-pollution"
            target="_blank"
            rel="noreferrer"
          >
            Particulate Matter
          </a>{' '}
          pollution. Particulate matter is made up of airborne particles that can be composed of a
          combination of solids and liquids. These particles are smaller than the width of a human
          hair and we can breathe them in without noticing.{' '}
          <a
            href="https://www.cleanairhub.org.uk/clean-air-information/what-can-i-do/how-can-i-protect-myself-and-my-family-in-my-own-home"
            target="_blank"
            rel="noreferrer"
          >
            More information on home burning is available here.
          </a>
        </>
      ),
      image: FireplaceImage,
    },
  },
  {
    id: QuestionId.Q21WoodBurnerType,
    type: 'options',
    label: 'What type of wood-burning stove do you have?',
    filter: filters.hasWoodBurner,
    options: [
      { label: 'Defra approved/ Eco-design ready stove', value: StoveType.Defra },
      { label: 'Non-Defra approved/ Conventional stove', value: StoveType.NonDefra },
      { label: 'Pellet stove', value: StoveType.Pellet },
      { label: 'Open fireplace', value: StoveType.Open },
    ],
    info: {
      title: didYouKnow,
      body: `Where possible avoid using open fires that burn solid fuels, free standing gas heaters and candles. If you have to use solid fuels, try to make sure these are smokeless or if you’re burning wood make sure it’s dry and well-seasoned so it burns efficiently and with less pollution.`,
      image: FireplaceImage,
    },
  },
  {
    id: QuestionId.Q22WoodBurnerHours,
    type: 'twoNumber',
    label:
      'On average, what is the total number of hours you operate your wood burning stove each week?',
    filter: filters.hasWoodBurner,
    info: {
      image: FireplaceImage,
    },
    subLabel1: 'Winter',
    subLabel2: 'Summer',
  },
  {
    id: QuestionId.Q23GasBoiler,
    type: 'options',
    options: yesNo,
    label: 'Do you use a gas boiler at home?',
    info: {
      image: FireplaceImage,
    },
  },
  {
    id: QuestionId.Q24BoilerFuel,
    type: 'options',
    label: 'What is the type of your boiler fuel?',
    options: [
      {
        label: 'Natural Gas',
        value: BoilerFuel.NaturalGas,
      },
      {
        label: 'Gas Oil',
        value: BoilerFuel.GasOil,
      },
    ],
    info: {
      image: FireplaceImage,
    },
    filter: filters.gasBoiler,
  },
  {
    id: QuestionId.Q25ElectricFuelPump,
    type: 'options',
    label: 'Do you use an electric heat pump?',
    options: yesNo,
    info: {
      image: FireplaceImage,
    },
  },
  {
    id: QuestionId.Q26OnlinePurchases,
    type: 'options',
    label:
      'Excluding food and takeaway deliveries, do you make online purchases (E.g. Clothing, Beauty, Technology, etc.)?',
    options: yesNo,
    info: deliveryInfo1,
  },
  {
    id: QuestionId.Q27OnlinePurchaseNum,
    type: 'number',
    label: 'On average, how many online purchases do you make each week?',

    info: deliveryInfo1,
    filter: filters.onlineDelivery,
  },
  {
    id: QuestionId.Q28OnlineGroceries,
    type: 'options',
    label: 'Do you shop for grocery online (E.g. Sainsbury, Ocado, Asda, etc.)?',
    options: yesNo,

    info: deliveryInfo2,
  },
  {
    id: QuestionId.Q29OnlineGroceryNum,
    type: 'number',
    label: 'On average, how many grocery shops delivery do you make each week?',
    info: deliveryInfo2,
    filter: filters.onlineGrocery,
  },
];

export const questionsById: Map<QuestionId, Question> = new Map(questions.map((q) => [q.id, q]));
