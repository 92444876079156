import { EmImpact } from './types';

const parcelEmissions = {
  pm25: 0.740769231,
  nox: 12.6538461538,
};

const groceryEmissions = {
  pm25: 0,
  nox: 0.4970963158,
};

const transportCosts = {
  pm25: 203331,
  nox: 10699,
};

export function deliveryEmissions(parcels: number, groceries: number): EmImpact {
  const result: EmImpact = {
    cost: {
      pm25: 0,
      nox: 0,
    },
    emissions: {
      pm25: 0,
      nox: 0,
    },
  };

  if (parcels > 0) {
    result.emissions.nox += parcelEmissions.nox * parcels * 52;
    result.emissions.pm25 += parcelEmissions.pm25 * parcels * 52;
  }

  if (groceries > 0) {
    result.emissions.nox += groceryEmissions.nox * groceries * 52;
    result.emissions.pm25 += groceryEmissions.pm25 * groceries * 52;
  }

  result.cost.nox += result.emissions.nox * (transportCosts.nox / 10 ** 6);
  result.cost.pm25 += result.emissions.pm25 * (transportCosts.pm25 / 10 ** 6);

  return result;
}
