import styled from 'styled-components/macro';
import { SubmitButton } from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import { bodyStyles, titleStyles } from '../../styles/text';

const FeedbackForm = styled.form`
  width: 40.8rem;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  text-align: center;
`;

const FeedbackText = styled.textarea`
  background: var(--apc-white);
  padding: 2.4rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  text-align: left;
  ${bodyStyles.lg}
`;

const FeedbackTitle = styled.div`
  ${titleStyles.lg};
`;

const FeedbackPara = styled.p`
  ${bodyStyles.lg};
`;

const ShareCheckbox = styled(Checkbox)`
  text-align: left;
  ${bodyStyles.sm}
`;

export const FeedbackWrapper = styled.div`
  margin-top: 4.8rem;
  background: rgba(49, 50, 53, 0.4);
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;
  padding: 8.2rem 2.4rem;
`;

export const ShareFeedback = () => {
  return (
    <FeedbackWrapper>
      <FeedbackTitle>What would help you take more of these clean air actions?</FeedbackTitle>
      <FeedbackForm
        action="mailto:cleanerair@globalactionplan.org.uk?subject=Calculator Feedback"
        method="post"
        encType="text/plain"
      >
        <FeedbackPara>What is currently stopping you from taking on some of these pledges?</FeedbackPara>
        <FeedbackText name="feedback" placeholder="Feedback..." />
        <ShareCheckbox
          name="share"
          value="ticked"
          label="Please tick this box if you are happy for us to contact you via email to ask for feedback on your experience using the Air Pollution Calculator"
        />
        <SubmitButton>Share feedback</SubmitButton>
      </FeedbackForm>
    </FeedbackWrapper>
  );
};
