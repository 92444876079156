import React from 'react';
import { AppContent, AppWrapper, GlobalStyles } from './App.styles';
import { Quiz } from './pages/Quiz';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Results } from './pages/Results';
import { CalcProvider } from './CalcContext';
import { Methodology } from './pages/Methodology';
import { TakeAction } from './pages/TakeAction';
import { Start } from './pages/Start';
import { Footer } from './components/Footer';
// import { PopulatedCalcContext } from './CalcContext.mocks';

const App = () => {
  return (
    <Router>
      <CalcProvider>
        <GlobalStyles />
        <AppWrapper>
          <AppContent>
            <Switch>
              <Route path="/" exact component={Start} />
              <Route path="/quiz" component={Quiz} />
              <Route path="/results" component={Results} />
              <Route path="/methodology" component={Methodology} />
              <Route path="/take-action" component={TakeAction} />
            </Switch>
          </AppContent>
          <Footer />
        </AppWrapper>
      </CalcProvider>
    </Router>
  );
};

export default App;
