import { VFC } from 'react';
import styled from 'styled-components/macro';
import { bodyStyles } from '../../styles/text';

const Wrapper = styled.div`
  display: flex;
  ${bodyStyles.sm}
  margin-bottom: 2.4rem;
`;

type Stage = 'results' | 'change' | 'action';

const Item = styled.span<{ active: boolean }>`
  display: flex;
  color: ${({ active }) => (active ? `var(--apc-yellow)` : `var(--apc-white)`)};

  &:not(:last-child) {
    margin-right: 0.8rem;

    &::after {
      content: '>';
      display: 'inline-block';
      color: var(--apc-slate);
      margin-left: 0.8rem;
    }
  }
`;

export const Crumbs: VFC<{ stage: Stage }> = ({ stage }) => {
  return (
    <Wrapper>
      <Item active={stage === 'results'}>Results</Item>
      <Item active={stage === 'action'}>Take action</Item>
    </Wrapper>
  );
};
