export class EmissionFactor {
  constructor(
    private minSpeed: number,
    private maxSpeed: number,
    private alpha: number,
    private beta: number,
    private gamma: number,
    private delta: number,
    private epsilon: number,
    private zita: number,
    private hta: number,
    private reductionFactor: number
  ) {}

  public calculate(avgSpeed: number) {
    const actualAverageSpeed = Math.min(Math.max(avgSpeed, this.minSpeed), this.maxSpeed);

    return (
      ((this.alpha * actualAverageSpeed ** 2 +
        this.beta * actualAverageSpeed +
        this.gamma +
        this.delta / actualAverageSpeed) /
        (this.epsilon * actualAverageSpeed ** 2 + this.zita * actualAverageSpeed + this.hta)) *
      (1 - this.reductionFactor)
    );
  }
}
