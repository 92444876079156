import { VFC } from 'react';
import styled from 'styled-components/macro';
import { ExtraPledge, QuizPledge } from '../../../calc/types';
import { PledgeButton } from './PledgeButton';

export interface PledgeListProps {
  pledges: QuizPledge[] | ExtraPledge[];
  onToggle: (pledge: QuizPledge | ExtraPledge) => void;
  className?: string;
}

const PledgeListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const PledgeList: VFC<PledgeListProps> = ({ pledges, onToggle, className }) => {
  return (
    <PledgeListWrapper className={className}>
      {pledges.map((pledge) => (
        <PledgeButton key={pledge.id} pledge={pledge} onClick={() => onToggle(pledge)} />
      ))}
    </PledgeListWrapper>
  );
};
