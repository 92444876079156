import { ResidenceType } from './residence';
import { EmImpact } from './types';

export enum StoveType {
  Defra = 'Defra',
  NonDefra = 'NonDefra',
  Pellet = 'Pellet',
  Open = 'Open',
}

export enum BoilerFuel {
  NaturalGas = 'NaturalGas',
  GasOil = 'GasOil',
}

export const stoveFactors = {
  [StoveType.Defra]: {
    pm25: 370,
    nox: 80,
    rating: 0.030857143,
  },
  [StoveType.NonDefra]: {
    pm25: 740,
    nox: 50,
    rating: 0.033230769,
  },
  [StoveType.Pellet]: {
    pm25: 60,
    nox: 80,
    rating: 0.027,
  },
  [StoveType.Open]: {
    pm25: 820,
    nox: 50,
    rating: 0.063529412,
  },
};

export const stoveDamage = {
  pm25: 85_753,
  nox: 13_950,
};

const winterLength = 21;
const summerLength = 32;

export function stoveEmissions(type: StoveType, otherPeople: number, hours: [number, number]) {
  const factors = stoveFactors[type];
  const [winter, summer] = hours;

  const seasonFactor = winter * winterLength + summer * summerLength;

  const noxEmission = (factors.nox * factors.rating * seasonFactor) / (otherPeople + 1);
  const pm25Emission = (factors.pm25 * factors.rating * seasonFactor) / (otherPeople + 1);

  const noxCost = noxEmission * (stoveDamage.nox / 10 ** 6);
  const pm25Cost = pm25Emission * (stoveDamage.pm25 / 10 ** 6);

  return {
    noxEmission,
    pm25Emission,
    noxCost,
    pm25Cost,
  };
}

export const boilerFactors = {
  [BoilerFuel.NaturalGas]: {
    pm25: 0.2,
    nox: 42,
    efficiency: 1,
  },
  [BoilerFuel.GasOil]: {
    pm25: 1.5,
    nox: 69,
    efficiency: 1.014302741,
  },
};

export const boilerRating = {
  [ResidenceType.Small]: 28.8,
  [ResidenceType.Medium]: 43.2,
  [ResidenceType.Large]: 61.2,
};

const domesticCombustionCosts = {
  pm25: 85753,
  nox: 13950,
};

export function gasBoilerEmissions(
  fuel: BoilerFuel,
  residence: ResidenceType,
  cohabitants: number
): EmImpact {
  const factors = boilerFactors[fuel];
  const rating = boilerRating[residence];

  const nox = (factors.nox * rating * factors.efficiency) / (cohabitants + 1);
  const pm25 = (factors.pm25 * rating * factors.efficiency) / (cohabitants + 1);

  return {
    emissions: {
      nox,
      pm25,
    },
    cost: {
      nox: nox * (domesticCombustionCosts.nox / 10 ** 6),
      pm25: pm25 * (domesticCombustionCosts.pm25 / 10 ** 6),
    },
  };
}
