import * as React from 'react';
import { FC, useEffect } from 'react';
import { QuizHeader } from '../QuizHeader';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';

export const ContentWrapper = styled.div``;

export const ContentPage: FC<{ className?: string }> = ({ children, className }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ContentWrapper className={className}>
      <QuizHeader kind="results" />
      <ContentWrapper>{children}</ContentWrapper>
    </ContentWrapper>
  );
};
