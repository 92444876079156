import { EmFactors, EmImpact } from './types';

export enum ResidenceType {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

const emFactors: EmFactors = {
  pm25: 1.540882645,
  nox: 75.87587396,
};

const outputRatings: Record<ResidenceType, number> = {
  [ResidenceType.Small]: 6.48,
  [ResidenceType.Medium]: 10.44,
  [ResidenceType.Large]: 15.48,
};

const domesticCost: EmFactors = {
  pm25: 85753,
  nox: 13950,
};

export function residenceEmissions(type: ResidenceType, cohabitors: number): EmImpact {
  const outputRating = outputRatings[type];
  const nox = (emFactors.nox * outputRating) / (cohabitors + 1);
  const pm25 = (emFactors.pm25 * outputRating) / (cohabitors + 1);

  return {
    emissions: {
      nox,
      pm25,
    },
    cost: {
      nox: nox * (domesticCost.nox / 10 ** 6),
      pm25: pm25 * (domesticCost.pm25 / 10 ** 6),
    },
  };
}
