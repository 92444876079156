import React, { FC, ChangeEvent, KeyboardEvent, useCallback, ReactNode } from 'react';
import { TextWrapper, TextLabel, TextInput } from './TextField.styles';

interface TextFieldProps {
  label: ReactNode;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  value: string;
  list: string;
}

export const TextField: FC<TextFieldProps> = ({ label, onEnter, value, ...props }) => {
  const onKeyPressed = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && onEnter) {
        onEnter();
      }
    },
    [onEnter]
  );

  const ref = useCallback((node: HTMLInputElement | null) => {
    if (node) {
      node.focus();
    }
  }, []);

  return (
    <TextWrapper>
      <TextLabel>{label}</TextLabel>
      <TextInput onKeyPress={onKeyPressed} value={`${value}`} {...props} ref={ref} />
    </TextWrapper>
  );
};
