import React, { VFC } from 'react';
import styled from 'styled-components/macro';
import { TitleSm } from '../../styles/text';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30rem;
`;

const Circle = styled.svg`
  transform: translateX(50%);
  margin-left: auto;
`;

const CircleWrap = styled.div`
  display: flex;
`;

const Rows = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;

  &:nth-child(2) {
    transform: translateY(-20%);
  }

  &:nth-child(3) {
    transform: translateY(-40%);
  }

  ${CircleWrap} {
    position: relative;
    min-width: 20rem;
  }

  ${CircleWrap}::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-bottom: 1px solid var(--apc-white);
  }
`;

const NatAverage = styled(Row)`
  ${CircleWrap}::before {
    border-bottom: 1px dashed var(--apc-white);
  }
`;

const You = styled(Row)``;
const Suggested = styled(Row)``;

const LabelValue = styled.div`
  margin-top: 0.8rem;
`;

const Label = styled.div`
  width: 12.8rem;
  position: relative;
  flex-shrink: 0;
`;

export const Comparison: VFC<{ score: number; target: number; className?: string }> = ({
  score,
  target,
  className,
}) => {
  const max = Math.max(score, 100);

  const biggestArea = Math.PI * 100 ** 2;

  const avgRatio = 100 / max;
  const youRatio = score / max;
  const targetRatio = youRatio * target;
  const minSize = 10;

  const avgSize = Math.max(minSize, Math.sqrt((biggestArea * avgRatio) / Math.PI) * 2);
  const youSize = Math.max(minSize, Math.sqrt((biggestArea * youRatio) / Math.PI) * 2);
  const targetSize = Math.max(minSize, Math.sqrt((biggestArea * targetRatio) / Math.PI) * 2);

  const circles = [
    {
      size: avgSize,
      node: (
        <NatAverage>
          <Label>National Average</Label>
          <CircleWrap>
            <Circle width={avgSize} height={avgSize} viewBox="0 0 100 100">
              <circle cx="50" cy="50" r="50" style={{ fill: 'var(--apc-lead)' }} />
            </Circle>
          </CircleWrap>
        </NatAverage>
      ),
    },
    {
      size: youSize,
      node: (
        <You>
          <Label>
            You
            <LabelValue>{score}%</LabelValue>
          </Label>
          <CircleWrap>
            <Circle width={youSize} height={youSize} viewBox="0 0 100 100">
              <circle cx="50" cy="50" r="50" style={{ fill: 'var(--apc-white)' }} />
            </Circle>
          </CircleWrap>
        </You>
      ),
    },

    {
      size: targetSize,
      node:
        targetSize === 0 ? null : (
          <Suggested>
            <Label>
              Suggested target
              <LabelValue>{Math.round(score * target)}%</LabelValue>
            </Label>
            <CircleWrap>
              <Circle width={targetSize} height={targetSize} viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="50" style={{ fill: 'var(--apc-yellow)' }} />
              </Circle>
            </CircleWrap>
          </Suggested>
        ),
    },
  ];

  circles.sort((a, b) => b.size - a.size);

  return (
    <Wrapper className={className}>
      <TitleSm>Your weekly pollution comparison</TitleSm>
      <Rows>{circles.map((c) => c.node)}</Rows>
    </Wrapper>
  );
};
