import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components/macro';
import { ContentPage } from '../../components/ContentPage';
import { TitleXl } from '../../styles/text';

const Footnotes = styled.div`
  border-top: 1px solid white;
  padding-top: 1.6rem;
  word-break: break-all;

  ul {
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  sup {
    margin-right: 0.6rem;
  }
`;

const Wrapper = styled(ContentPage)`
  p {
    margin: 1.2rem 0;
  }
`;

export const Methodology: FC = () => {
  return (
    <Wrapper>
      <TitleXl>How we calculated your air pollution footprint</TitleXl>
      <p>
        This air pollution calculator is designed to be an indicator of an individual’s personal contribution to UK air
        pollution, and to show the most important activities and simple steps we can all take to reduce it.
      </p>
      <p>The calculator is a partnership between Global Action Plan and Imperial College London.</p>
      <p>
        The research to develop the methodology and calculations used to build the calculator are the work of Yuanyang
        Liu, Jing-Yuan Wie and Dr Marc Stettler of Imperial College London who, along with digital developer Jon Rimmer,
        made this project possible.
      </p>
      <p>
        In summary, the methodology estimates a person’s air pollution footprint resulting from their travel activities,
        heating &amp; electricity consumption and deliveries activities. The calculator estimates emissions of NOx and
        PM2.5, which are two air pollutants that account for the majority of health impacts associated with air
        pollution in the UK
        <sup>1</sup>. Further information on these pollutants is provided below.
      </p>
      <p>
        In order to provide an indicator of a person’s air pollution footprint in the space of a few minutes, it is not
        possible to cover all sources of emissions, and so the calculator focuses on the activities listed above. The
        result is therefore an indication and should not be treated as a precise value of an individual’s full
        footprint. The methodology will continue to be updated in future iterations.
      </p>
      <h2>Why do you need to know where I live?</h2>
      <p>
        There is a chain of events that connect an activity to an air pollution impact – this is called the impact
        pathway. For example, an activity such as driving to work generates a certain amount of air pollutant emissions.
      </p>
      <p>
        As these emissions are released into the atmosphere, they contribute to increasing the concentrations of
        pollution and can lead to the build up of pollution in particular areas, for example near busy roads.
      </p>
      <p>
        The increased concentrations of pollution are experienced by people who live nearby, and they are exposed to
        higher levels of pollution. This exposure to pollution can cause significant and long-lasting health effects,
        which could contribute to an increased risk of illness and shorter lifespans<sup>2</sup>.
      </p>
      <p>
        The impact of air pollution therefore depends on how much is emitted, what type of pollution is emitted, and
        where it is emitted. The impact of one unit of pollution will be higher in areas where the population density is
        higher.
      </p>
      <p>
        The calculator accounts for these key factors by using “damage costs” published by Defra (Department for
        Environment, Food & Rural Affairs), which estimate the societal costs associated with emissions from different
        sectors and different areas of the UK<sup>3</sup>.
      </p>

      <h2>What is PM2.5?</h2>

      <p>
        PM2.5 stands for particulate matter with an aerodynamic diameter of less than 2.5 micrometres. In the UK,
        emissions of PM2.5 have declined by 54% since 1990 due mainly to a reduction in coal use.
      </p>
      <p>
        Emissions from the residential sector fell between 1990 and 2002 as less coal was used to heat homes. However,
        this trend reversed and PM2.5 emissions from homes are now at similar levels to 1990 as a result of increased
        use of wood burning stoves. Wood now contributes 87% of PM2.5 emissions from domestic combustion, compared with
        13% in 1990<sup>4</sup>.
      </p>
      <p>
        Wood burning stoves are in the calculator as these have grown in popularity, yet contribute to significant air
        pollution. The calculator asks questions to determine the type of stove that is used as newer models are
        significantly less polluting.
      </p>

      <h2>What is NOx?</h2>

      <p>
        NOx stands for oxides of nitrogen and it includes nitric oxide (NO) and nitrogen dioxide (NO2). Emissions of NOx
        contribute to NO2 pollution. The main source of NOx is the transport sector, accounting for 52% of emissions
        (road transport and other transport).
      </p>
      <p>
        Most of the road transport emissions are from diesel vehicles. Since 1990, emissions from road transport have
        fallen by 79% due to the introduction of catalytic converters and stricter vehicle emissions regulations, known
        as the Euro Standards<sup>5</sup>, and we therefore include a question asking the age of the vehicle to estimate
        the Euro Standard that it complies with.
      </p>
      <p>
        The calculator includes commuting and leisure travel activities as the likely main source of an individual’s NOx
        emissions. There is a question asking the age of the vehicle, and this is to account for the increasing
        stringency in vehicle emissions standards.
      </p>
      <p>
        The calculator does not yet account for NOx emissions from domestic combustion other than wood burning stoves.
        This will be updated in a later edition, however for now the calculator focuses on the contribution from
        transport sources of NOx.
      </p>
      <p>
        The transport sources that are captured on the calculator account for approximately seven times more NOx
        annually at the national level compared to domestic gas boilers (calculated using data from the National
        Atmospheric Emissions Inventory).
      </p>

      <h2>Where did you obtain the information on emissions factors and other data?</h2>
      <p>For all emissions sources, we have used the latest information available from official sources.</p>
      <p>Road Transport</p>
      <ul>
        <li>
          Emissions factors for vehicles are from the European Environment Agency Emissions Inventory Guidebook (Tier 3
          methodology). Note, this does not yet include non-exhaust emissions<sup>6</sup>.
        </li>
        <li>
          Average vehicle speeds in different areas are from the Department for Transport data. This includes Table
          CGN0501: Average speed, delay and reliability of travel times<sup>7</sup>, Table NTS0303: Average number of
          trips, stages, miles and time spent travelling by main mode<sup>8</sup> and Table NTS9910: Average trip length
          by main mode, region and Rural-Urban Classification: England<sup>15</sup>.
        </li>
        <li>
          Average emissions factors for buses are calculated from the total UK bus emissions inventory<sup>9</sup> and
          table BUS0301 from Department for Transport<sup>10</sup>.
        </li>
      </ul>
      <p>Electricity consumption and domestic heating</p>
      <ul>
        <li>
          Emission factors for electricity consumption are from the UK’s National Atmospheric Emissions Inventory
          (NAEI). Average emissions factors (in units of g/GJ) for public electricity consumption for NOx and PM2.5 are
          estimated by dividing total energy consumption by total emissions reported in the NAEI.
        </li>
        <li>
          Emission factors for domestic heating (wood burning stoves and gas boilers) are from Defra
          <sup>11</sup>. These are provided for NOx and PM2.5 in g/GJ.
        </li>
        <li>
          We have asked how many people are in your household and divided the emissions factors by this number to obtain
          an emissions per person.
        </li>
        <li>
          Typical consumption of electricity and gas boilers are taken from the ofgem Typical Domestic Consumption
          Values (TDCVs)<sup>16</sup>. TDCVs are industry standard values for the annual gas and electricity usage of a
          typical domestic consumer. These values can also be found on energy suppliers’ communications, on their
          websites and on price comparison sites. TDCVs are provided of low, medium and high categories and we use size
          of your residence as a proxy for this.
        </li>
      </ul>

      <p>Deliveries</p>

      <ul>
        <li>
          Emissions of NOx and PM2.5 per delivery for parcel and food shop deliveries are estimated based on publicly
          available information from delivery providers in the UK<sup>17</sup> <sup>18</sup>.
        </li>
      </ul>

      <p>Damage Costs</p>
      <ul>
        <li>
          The latest damage costs are from Defra<sup>12</sup>.
        </li>
      </ul>

      <h2>How did you calculate the average person's air pollution footprint in the UK?</h2>

      <p>
        There are no official estimates of the average air pollution footprint per person in the UK. In fact, we think
        our calculator is the first attempt to estimate an individual's contribution to this issue.
      </p>
      <p>
        To put your result in context, we have compared it to the UK average for the same activities that are covered in
        the calculator.
      </p>
      <p>
        To calculate the footprint of the average person in the UK, we extracted the total NOx and PM2.5 emissions for
        private car use, buses, motorcycles, electricity consumptions, residential domestic combustions and light duty
        vehicles that occur in the UK each year
        <sup>13</sup>.
      </p>
      <p>
        We then multiplied these emissions by the official average damage costs for NOx and PM2.5 in the UK<sup>14</sup>
        .
      </p>
      <p>
        Finally, we divided monetary value by the total population in the UK in 2018 to give an average monetary value
        of damage caused per person in the UK.
      </p>
      <p>The result shown is your result relative to this average value.</p>
      <p>
        We have decided not to show the absolute value of your damage cost because the calculator is only an indicator
        of your footprint, and secondly these damage costs are typically considered in the context of other
        environmental or societal costs, and are less meaningful on their own.
      </p>

      <h2>How we calculated pledges/assumptions that sit behind it</h2>

      <p>
        In order to calculate the decrease air pollution footprint in the results of the Air Pollution Calculator, we
        had to make some general assumptions that feed into the calculations. Not everyone will take this exact action,
        but it is designed to be used as a guide to showcase what impact would be had if they were to take cleaner air
        actions.
      </p>

      <p>Commuting</p>

      <ul>
        <li>
          If you pledge to “Travel to work using the train or active travel methods”, we assume that equates to choosing
          "Walk/Cycle" as your commute option.
        </li>
        <li>
          If you pledge to “Switch to a fully electric car, we assume that this equates to choosing the "Electric" car
          type option in the calculator. Our calculator doesn't yet included non-tailpipe emissions from brakes and
          tyres so is not included in this calculation.
        </li>
        <li>
          If you pledge to “Car-pool with a friend or co-worker”, we assume that this equates one extra traveller in
          your car which results in a 50% reduction in your personal emissions.
        </li>
      </ul>

      <p>Leisure travel</p>

      <ul>
        <li>
          If you pledge to “Travel less by car, using public transportation or active travel instead”, we assume that
          this equates a 50% reduction in your hours spent in the car.
        </li>
        <li>
          If you pledge to “Switch to a fully electric car, we assume that this equates to choosing the "Electric" car
          type option in the calculator. Our calculator doesn't yet included non-tailpipe emissions from brakes and
          tyres so is not included in this calculation
        </li>
        <li>
          If you pledge to “Make fewer bus journeys”, we assume that this equates to a 50% reduction in your bus travel
          hours.
        </li>
      </ul>

      <p>Domestic energy</p>

      <ul>
        <li>
          If you pledge to “Stop using a wood-burning stove”, we assume that this equates to removing all pollution
          impact that they had been creating through using an open fire or log burner.
        </li>
        <li>
          If you pledge to “Use my wood-burning stove less frequently”, we assume that this equates to a 50% reduction
          in your winter and summer burning hours.
        </li>
        <li>
          If you pledge to “Switch to an electric heat pump”, we assume that this equates choosing the option "Yes" for
          whether you have an electric heat pump installed.
        </li>
      </ul>

      <p>Delivery</p>

      <ul>
        <li>
          If you pledge to “Combine or reduce my online deliveries”, we assume that this equates to a 50% reduction in
          your number of grocery and shopping deliveries.
        </li>
      </ul>

      <Footnotes>
        <li>
          <sup>1</sup>
          <a href="https://www.gov.uk/government/publications/clean-air-strategy-2019">
            https://www.gov.uk/government/publications/clean-air-strategy-2019
          </a>
        </li>
        <li>
          <sup>2</sup>
          <a href="https://www.gov.uk/government/publications/assess-the-impact-of-air-quality/air-quality-appraisal-impact-pathways-approach">
            https://www.gov.uk/government/publications/assess-the-impact-of-air-quality/air-quality-appraisal-impact-pathways-approach
          </a>
        </li>
        <li>
          <sup>3</sup>
          <a href="https://www.gov.uk/government/publications/assess-the-impact-of-air-quality/air-quality-appraisal-damage-cost-guidance">
            https://www.gov.uk/government/publications/assess-the-impact-of-air-quality/air-quality-appraisal-damage-cost-guidance
          </a>
        </li>
        <li>
          <sup>4</sup>
          <a href="https://naei.beis.gov.uk/overview/pollutants?pollutant_id=122">
            https://naei.beis.gov.uk/overview/pollutants?pollutant_id=122
          </a>
        </li>
        <li>
          <sup>5</sup>
          <a href="https://naei.beis.gov.uk/overview/pollutants?pollutant_id=6">
            https://naei.beis.gov.uk/overview/pollutants?pollutant_id=6
          </a>
        </li>
        <li>
          <sup>6</sup>
          <a href="https://www.eea.europa.eu/publications/emep-eea-guidebook-2019/part-b-sectoral-guidance-chapters/1-energy/1-a-combustion/1-a-3-b-i/view">
            https://www.eea.europa.eu/publications/emep-eea-guidebook-2019/part-b-sectoral-guidance-chapters/1-energy/1-a-combustion/1-a-3-b-i/view
          </a>
        </li>
        <li>
          <sup>7</sup>
          <a href="https://www.gov.uk/government/statistical-data-sets/average-speed-delay-and-reliability-of-travel-times-cgn">
            https://www.gov.uk/government/statistical-data-sets/average-speed-delay-and-reliability-of-travel-times-cgn
          </a>
        </li>
        <li>
          <sup>8</sup>
          <a href="https://www.gov.uk/government/statistical-data-sets/nts03-modal-comparisons">
            https://www.gov.uk/government/statistical-data-sets/nts03-modal-comparisons
          </a>
        </li>
        <li>
          <sup>9</sup>
          <a href="https://naei.beis.gov.uk/data/data-selector">https://naei.beis.gov.uk/data/data-selector</a>
        </li>
        <li>
          <sup>10</sup>
          <a href="https://www.gov.uk/government/statistical-data-sets/bus03-passenger-distance-travelled">
            https://www.gov.uk/government/statistical-data-sets/bus03-passenger-distance-travelled
          </a>
        </li>
        <li>
          <sup>11</sup>
          <a href="https://laqm.defra.gov.uk/review-and-assessment/tools/combustion-emission-factors.html">
            https://laqm.defra.gov.uk/review-and-assessment/tools/combustion-emission-factors.html
          </a>
        </li>
        <li>
          <sup>12</sup>
          <a href="https://uk-air.defra.gov.uk/assets/documents/reports/cat09/1902271109_Damage_cost_update_2018_FINAL_Issue_2_publication.pdf">
            https://uk-air.defra.gov.uk/assets/documents/reports/cat09/1902271109_Damage_cost_update_2018_FINAL_Issue_2_publication.pdf
          </a>
        </li>
        <li>
          <sup>13</sup>
          <a href="https://naei.beis.gov.uk/data/data-selector">https://naei.beis.gov.uk/data/data-selector</a>
        </li>
        <li>
          <sup>14</sup>
          <a href="https://www.gov.uk/government/publications/assess-the-impact-of-air-quality/air-quality-appraisal-damage-cost-guidance">
            https://www.gov.uk/government/publications/assess-the-impact-of-air-quality/air-quality-appraisal-damage-cost-guidance
          </a>
        </li>
        <li>
          <sup>15</sup>
          <a href="https://www.gov.uk/government/statistical-data-sets/nts99-travel-by-region-and-area-type-of-residence">
            https://www.gov.uk/government/statistical-data-sets/nts99-travel-by-region-and-area-type-of-residence
          </a>
        </li>
        <li>
          <sup>16</sup>
          <a href="https://www.ofgem.gov.uk/gas/retail-market/monitoring-data-and-statistics/typical-domestic-consumption-values">
            https://www.ofgem.gov.uk/gas/retail-market/monitoring-data-and-statistics/typical-domestic-consumption-values
          </a>
        </li>
        <li>
          <sup>17</sup>
          <a href="https://www.dhl.com/content/dam/dhl/global/core/documents/pdf/sustainability-report.pdf">
            https://www.dhl.com/content/dam/dhl/global/core/documents/pdf/sustainability-report.pdf
          </a>
        </li>
        <li>
          <sup>18</sup>
          <a href="https://www.rbkc.gov.uk/idoxWAM/doc/Revision%20Content-2427130.pdf?extension=.pdf&id=2427130&location=VOLUME2&contentType=application/pdf&pageCount=1">
            https://www.rbkc.gov.uk/idoxWAM/doc/Revision%20Content-2427130.pdf?extension=.pdf&id=2427130&location=VOLUME2&contentType=application/pdf&pageCount=1
          </a>
        </li>
      </Footnotes>
    </Wrapper>
  );
};
