import { VFC } from 'react';
import styled, { css } from 'styled-components';
import { ExtraPledge, Pledge, QuizPledge } from '../../../calc/types';
import { breakpointsMin } from '../../../styles';
import { TitleXl } from '../../../styles/text';
import PledgeChart from './PledgeChart';
import { PledgeList } from './PledgeList';

export interface PledgeGroupProps {
  categoryName: string;
  currentRelative: number;
  currentValue: number;
  imageSrc: string;
  pledges: QuizPledge[];
  possibleValue: number;
  withActive: {
    target: number;
    you: number;
  } | null;
  onToggle: (pledge: Pledge) => void;
  index: number;
}

const PledgeGroupReduction = styled.div`
  color: var(--apc-yellow);
  padding: 0.8rem;
  background: var(--apc-lead);
`;

const PledgeGroupWrapper = styled.div<{ chart?: boolean }>`
  display: grid;
  gap: 4.8rem 1.6rem;

  ${({ chart }) =>
    chart
      ? css`
          grid-template:
            'image' auto
            'copy' auto
            'chart' auto
            'pledges' auto / 1fr;

          ${breakpointsMin.md} {
            padding: 0 4.8rem;
            grid-template:
              'image copy copy' auto
              'pledges pledges chart' auto / 40rem 1fr 45rem;
          }
        `
      : css`
          grid-template:
            'image' auto
            'copy' auto
            'pledges' auto / 1fr;

          ${breakpointsMin.md} {
            padding: 0 4.8rem;
            grid-template:
              'image copy copy' auto
              'pledges pledges pledges' auto / 40rem 1fr 45rem;
          }
        `}

  .copy {
    grid-area: copy;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    .lightning {
      margin-right: 1rem;
    }
  }

  .type-image {
    grid-area: image;
    width: 33rem;
    place-self: center;
  }

  .pledges {
    grid-area: pledges;
    position: relative;
  }

  .pledges-header {
    padding: 0.8rem;
    color: var(--apc-yellow);
    background-color: var(--apc-lead);
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0.8rem, -70%);
    z-index: 100;
  }
`;

const StyledPledgeChart = styled(PledgeChart)`
  grid-area: chart;
  align-self: start;

  ${breakpointsMin.md} {
    margin-left: 10rem;
  }
`;

const CategoryName = styled.div`
  color: var(--apc-yellow);
`;

export const PledgeGroup: VFC<PledgeGroupProps> = ({
  categoryName,
  currentRelative,
  currentValue,
  imageSrc,
  pledges,
  possibleValue,
  onToggle,
  withActive,
  index,
}) => {
  const categoryLower = categoryName.toLocaleLowerCase();

  return (
    <PledgeGroupWrapper chart={true}>
      <img className="type-image" src={imageSrc} alt="" />
      <div className="copy">
        <CategoryName>{categoryName}</CategoryName>
        <TitleXl>
          {categoryName} is about {Math.round((currentRelative ?? 0) * 100)}% of your total impact
        </TitleXl>
        <p className="impact">
          {(currentValue ?? 0) > 1
            ? [
                'This is higher than the average person in the UK. There are some key things you can do to decrease this - take a pledge today!',
                'This is higher than most other people in the UK. But you can take a pledge today to decrease your footprint!',
                'Your air pollution impact in this category is higher than the average person in UK - but by taking these pledges you can cut this down.',
              ][index % 3]
            : [
                `This is lower than most other people in the UK - but the work doesn't stop here! There are still more positive actions you can take - make a pledge today!`,
                'This is lower than the average person in the UK - well done! Keep going, but also consider going even further to decrease your air pollution footprint by making a pledge below',
                'Your air pollution impact in this category is lower than the average person in UK - great job! But you can still do more to decrease air pollution by making a pledge.',
              ][index % 3]}
        </p>
        <PledgeGroupReduction>
          <svg width="21" height="12" viewBox="0 0 21 12" fill="none" className="lightning">
            <path
              d="M16.4074 9.37465L11.25 4.21723L7.54453 7.91918C7.10508 8.35863 6.39492 8.35863 5.95547 7.91918L0.329484 2.29418C-0.109828 1.85473 -0.109828 1.14457 0.329484 0.705117C0.768867 0.264961 1.48113 0.264961 1.92059 0.705117L6.71836 5.53207L10.4555 1.83012C10.8949 1.39066 11.6051 1.39066 12.0445 1.83012L18 7.78207V4.84301C18 4.25238 18.5027 3.71801 19.125 3.71801C19.7473 3.71801 20.25 4.25238 20.25 4.84301V10.4996C20.25 11.1219 19.7473 11.6246 19.125 11.6246H13.5C12.8777 11.6246 12.375 11.1219 12.375 10.4996C12.375 9.87738 12.8777 9.37465 13.5 9.37465H16.4074Z"
              fill="white"
            />
          </svg>
          If you carry out {pledges.length > 1 ? 'these pledges' : 'this pledge'}, your {categoryLower} impact could
          reduce to {possibleValue}% of the national average.
        </PledgeGroupReduction>
      </div>

      {withActive && (
        <StyledPledgeChart
          pledged={withActive.you ?? 0}
          target={withActive.target ?? 0}
          orientation={index % 2 === 0 ? 'h' : 'v'}
        />
      )}

      <div className="pledges">
        <div className="pledges-header">🤞 I pledge to...</div>
        <PledgeList pledges={pledges} onToggle={onToggle} />
      </div>
    </PledgeGroupWrapper>
  );
};

export const ExtraPledgesGroup: VFC<{ pledges: ExtraPledge[]; onToggle: (pledge: Pledge) => void }> = ({
  pledges,
  onToggle,
}) => {
  return (
    <PledgeGroupWrapper>
      <img className="type-image" src="/images/transport-methods.svg" alt="" />
      <div className="copy">
        <CategoryName>Going the extra mile</CategoryName>
        <TitleXl>Talk to your friends, family and community about clean air actions</TitleXl>
        <p className="impact">
          Sharing your knowledge around what actions we can all take to decrease air pollution is an important part of
          the journey towards cleaner air - talk to your community today!
        </p>
      </div>
      <div className="pledges">
        <div className="pledges-header">🤞 I pledge to...</div>
        <PledgeList pledges={pledges} onToggle={onToggle} />
      </div>
    </PledgeGroupWrapper>
  );
};
