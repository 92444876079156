import React, { FC, useEffect } from 'react';
import { QuizWrapper, QuizQuestion, QuizInfo } from './Quiz.styles';
import { RouteComponentProps } from 'react-router-dom';
import { QuizForm } from '../components/QuizForm';
import QuizInfoContent from '../components/QuizInfoContent';
import { QuizHeader } from '../components/QuizHeader';
import { useCalc } from '../CalcContext';
import { QuestionId } from './Quiz.types';
import styled from 'styled-components/macro';
import { bodyStyles } from '../styles/text';

function categoryName(questionId: QuestionId): string {
  if (questionId >= QuestionId.Q26OnlinePurchases) {
    return 'Delivery';
  }

  if (questionId >= QuestionId.Q18ResidenceSize) {
    return 'Domestic Energy';
  }

  if (questionId >= QuestionId.Q11NonCommuteCar) {
    return 'Other Travel';
  }

  return 'Commuting';
}

export const CategoryName = styled.h2`
  ${bodyStyles.sm};
  color: var(--apc-yellow);
  margin-bottom: 1.8rem;
`;

export const Quiz: FC<
  { className?: string; onFinished: (answers: any) => void } & RouteComponentProps
> = ({ className }) => {
  const { state, setQuestion } = useCalc();

  useEffect(() => {
    if (state.questionId === QuestionId.End) {
      setQuestion(QuestionId.Q29OnlineGroceryNum);
    }
  }, [state, setQuestion]);

  if (state.questionId === QuestionId.End) {
    return null;
  }

  return (
    <>
      <QuizHeader kind="questions" />
      <CategoryName>{categoryName(state.questionId)}</CategoryName>
      <QuizWrapper className={className}>
        <QuizQuestion>
          <QuizForm />
        </QuizQuestion>
        <QuizInfo>
          <QuizInfoContent />
        </QuizInfo>
      </QuizWrapper>
    </>
  );
};
