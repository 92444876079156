import { calcResults } from '.';
import { FormState, QuestionId } from '../pages/Quiz.types';
import { TransportType } from '../questions';
import { CarType } from './cars';
import {
  CalcResults,
  ExtraPledge,
  QuizPledge,
  PledgeCategory,
  PledgeCategoryState,
  PledgeState,
  PledgeChecker,
  PledgeAnswerer,
} from './types';

const check = {
  all:
    (...checks: PledgeChecker[]): PledgeChecker =>
    (answers) =>
      checks.every((check) => check(answers)),
  equals:
    (id: QuestionId, ...values: any[]): PledgeChecker =>
    (answers) =>
      values.some((value) => value === answers[id]),
  above:
    (id: QuestionId, minimum: number): PledgeChecker =>
    (answers) => {
      const value = answers[id];
      return value !== null && !Number.isNaN(value) && value >= minimum;
    },
};

const answer = {
  replace:
    (id: QuestionId, value: any): PledgeAnswerer =>
    (answers) => ({ ...answers, [id]: value }),
  multiply:
    (id: QuestionId, factor: number): PledgeAnswerer =>
    (answers) => {
      const answer = answers[id];

      if (Array.isArray(answer)) {
        return {
          ...answers,
          [id]: answer.map((v) => {
            return Math.round((Number.isNaN(v) ? 0 : v) * factor);
          }),
        };
      } else {
        const answerNum = Number(answers[id]);
        return {
          ...answers,
          [id]: Math.round((Number.isNaN(answerNum) ? 0 : answerNum) * factor),
        };
      }
    },
};

export const pledgeDefinitions: QuizPledge[] = [
  {
    id: 'commuteActive',
    category: 'commuting',
    title: 'Travel to work using the train or active travel methods',
    check: check.equals(QuestionId.Q2Commute, TransportType.Car, TransportType.Bus, TransportType.Motorbike),
    answer: answer.replace(QuestionId.Q2Commute, TransportType.Train),
  },
  {
    id: 'commuteElec',
    category: 'commuting',
    title: 'Switch to a fully electric car',
    description:
      'If driving is essential, go electric. Hire an electric car, taxi or test drive an electric vehicle today.',
    check: check.equals(QuestionId.Q3CarType, CarType.Diesel, CarType.HybridPetrol, CarType.Petrol),
    answer: answer.replace(QuestionId.Q3CarType, CarType.Electric),
  },
  {
    id: 'commuteLess',
    category: 'commuting',
    title: 'Travel less by car',
    description: 'By working from home whenever possible or using the train or active travel methods',
    check: check.above(QuestionId.Q5CarHours, 2),
    answer: answer.multiply(QuestionId.Q5CarHours, 0.5),
  },
  {
    id: 'carpool',
    category: 'commuting',
    title: 'Car-pool with a friend or co-worker',
    description: 'Carpooling means there are less cars on the road, improving air quality.',
    check: check.all(
      check.equals(QuestionId.Q2Commute, TransportType.Car),
      check.equals(QuestionId.Q6CarTravellers, 0)
    ),
    answer: answer.replace(QuestionId.Q6CarTravellers, 1),
  },
  {
    id: 'dontIdle',
    category: 'commuting',
    title: 'Avoid idling my engine',
    description: 'If you drive, turn off your engine when your vehicle is stationary, and it is safe to do so.',
    check: check.equals(QuestionId.Q2Commute, TransportType.Car),
  },
  {
    id: 'leisureElec',
    category: 'leisure',
    title: 'Switch to a fully electric car',
    check: check.all(
      check.equals(QuestionId.Q11NonCommuteCar, true),
      check.equals(QuestionId.Q12NonCommuteCarType, CarType.Diesel, CarType.HybridPetrol, CarType.Petrol)
    ),
    answer: answer.replace(QuestionId.Q12NonCommuteCarType, CarType.Electric),
  },
  {
    id: 'leisureLess',
    category: 'leisure',
    title: 'Make fewer car journeys',
    description: 'Using public transportation or active travel instead',
    check: check.all(check.equals(QuestionId.Q11NonCommuteCar, true), check.above(QuestionId.Q14NonCommuteCarHours, 2)),
    answer: answer.multiply(QuestionId.Q14NonCommuteCarHours, 0.5),
  },
  {
    id: 'leisureLessBus',
    category: 'leisure',
    title: 'Make fewer bus journeys',
    check: check.all(check.equals(QuestionId.Q16NonCommuteBus, true), check.above(QuestionId.Q17NonCommuteBusHours, 2)),
    answer: answer.multiply(QuestionId.Q17NonCommuteBusHours, 0.5),
  },
  {
    id: 'noStove',
    category: 'domestic',
    title: 'Stop using a wood-burning stove',
    check: check.equals(QuestionId.Q20WoodBurner, true),
    answer: answer.replace(QuestionId.Q20WoodBurner, false),
    pledgeGroup: 'stove',
  },
  {
    id: 'lessStove',
    category: 'domestic',
    title: 'Use my wood-burning stove less frequently',
    description:
      'If it is essential, only burn dry, well-seasoned or “Ready-to-Burn” labelled wood, or smokeless fuel.',
    check(formState) {
      if (!formState[QuestionId.Q20WoodBurner]) {
        return false;
      }

      const answer = formState[QuestionId.Q22WoodBurnerHours] as [number, number] | null;
      return !!answer && (answer[0] > 1 || answer[1] > 1);
    },
    answer: answer.multiply(QuestionId.Q22WoodBurnerHours, 0.5),
    pledgeGroup: 'stove',
  },
  {
    id: 'pumpItUp',
    category: 'domestic',
    title: 'Switch to an electric heat pump',
    description: 'If you own your own home, look into switching to an electric heat pump.',
    check: check.equals(QuestionId.Q25ElectricFuelPump, false),
    answer: answer.replace(QuestionId.Q25ElectricFuelPump, true),
  },
  {
    id: 'lessDeliveries',
    category: 'delivery',
    title: 'Combine or reduce my online deliveries',
    check(formState) {
      const hasDeliveries = !!formState[QuestionId.Q26OnlinePurchases];
      const deliveries = formState[QuestionId.Q27OnlinePurchaseNum] ?? 0;
      const hasGroceries = !!formState[QuestionId.Q26OnlinePurchases];
      const groceries = formState[QuestionId.Q29OnlineGroceryNum] ?? 0;
      return (hasDeliveries && deliveries > 2) || (hasGroceries && groceries > 0);
    },
    answer(formState) {
      const deliveries = formState[QuestionId.Q27OnlinePurchaseNum] ?? 0;
      const groceries = formState[QuestionId.Q29OnlineGroceryNum] ?? 0;

      return {
        ...formState,
        [QuestionId.Q27OnlinePurchaseNum]: Math.round(deliveries * 0.5),
        [QuestionId.Q29OnlineGroceryNum]: Math.round(groceries * 0.5),
      };
    },
  },
];

export const extraPledges: ExtraPledge[] = [
  {
    id: 'shareInfo',
    category: 'extra',
    title: 'Share air pollution information and cleaner air action',
    description:
      'Talk to your friends, family and wider community about air pollution and what actions we can take to decrease it.',
  },
  {
    id: 'localChange',
    category: 'extra',
    title: 'Support local change',
    description:
      'Talk to your local Councillors and MP about air pollution in your area and ask what you can do to support the local plan (and if there isn’t one, demand one).',
  },
];

function resultsWithPledges(answers: FormState, pledges: QuizPledge[]): CalcResults | null {
  return calcResults({
    ...answers,
    ...pledges.filter((p) => p.answer).reduce((acc, pledge) => pledge.answer?.(acc) ?? acc, answers),
  });
}

function pledgeCategory(answers: FormState, possible: QuizPledge[], category: PledgeCategory): PledgeCategoryState {
  const pledges = possible.filter((p) => p.category === category);
  const active = pledges.filter((p) => p.active);

  return {
    pledges,
    withAll: resultsWithPledges(answers, pledges),
    withActive: resultsWithPledges(answers, active),
  };
}

export function calcPledges(
  answers: FormState,
  results: CalcResults | null,
  active: Record<string, string>
): PledgeState | null {
  if (!results) {
    return null;
  }

  const groupCount = new Map<string, number>();

  const possiblePledges: QuizPledge[] = pledgeDefinitions.flatMap((pledge) => {
    const isActive = pledge.pledgeGroup ? active[pledge.pledgeGroup] === pledge.id : !!active[pledge.id];
    const possible = pledge.check?.(answers);

    if (possible) {
      pledge.pledgeGroup && groupCount.set(pledge.pledgeGroup, (groupCount.get(pledge.pledgeGroup) ?? 0) + 1);
      return [{ ...pledge, active: isActive }];
    }

    return [];
  });

  const activePledges = possiblePledges.filter((p) => p.active).reverse();

  return {
    results: resultsWithPledges(answers, activePledges),
    commuting: pledgeCategory(answers, possiblePledges, 'commuting'),
    domestic: pledgeCategory(answers, possiblePledges, 'domestic'),
    leisure: pledgeCategory(answers, possiblePledges, 'leisure'),
    delivery: pledgeCategory(answers, possiblePledges, 'delivery'),
    extra: extraPledges.map((p) => ({ ...p, active: !!active[p.id] })),
    groupCount,
  };
}
