import { EmFactors } from './types';
import { EmissionFactor } from './vehicles';

export enum CarType {
  Diesel = 'Diesel',
  Petrol = 'Petrol',
  HybridPetrol = 'Hybrid Petrol',
  Electric = 'Electric',
}

export enum CarTech {
  Euro6D = 'Euro6D',
  Euro6ABC = 'Euro6ABC',
  Euro5 = 'Euro5',
  Euro4 = 'Euro4',
  Euro3 = 'Euro3',
}

export type CarEmissionMapping = Record<CarTech, EmissionFactor>;

const dieselNox: CarEmissionMapping = {
  [CarTech.Euro6ABC]: new EmissionFactor(
    10,
    130,
    0.0000667136,
    -0.011381467,
    0.945951727,
    1.923608149,
    -0.0000515046,
    0.004264272,
    1,
    0.176306450219664
  ),
  [CarTech.Euro6D]: new EmissionFactor(
    10,
    130,
    0.0000667136,
    -0.011381467,
    0.945951727,
    1.923608149,
    -0.0000515046,
    0.004264272,
    1,
    0.92
  ),
  [CarTech.Euro5]: new EmissionFactor(
    10,
    130,
    6.67136e-5,
    -0.011381467,
    0.945951727,
    1.923608149,
    -5.15046e-5,
    0.004264272,
    1,
    0
  ),
  [CarTech.Euro4]: new EmissionFactor(
    10,
    130,
    0.000562369,
    -0.076397361,
    4.198823205,
    6.24824e-10,
    4.61273e-15,
    -2.02804e-12,
    3.789551629,
    0
  ),
  [CarTech.Euro3]: new EmissionFactor(
    10,
    120,
    -0.000769688,
    0.111214128,
    4.681975128,
    1.15175e-9,
    -0.002377223,
    0.329466291,
    1.66239354,
    0
  ),
};

const petrolNox6 = new EmissionFactor(
  5,
  130,
  -0.000314541,
  0.103056777,
  0.239056576,
  -0.339279149,
  0.034535828,
  1.986012746,
  1.26376326,
  0
);

const petrolNox: CarEmissionMapping = {
  [CarTech.Euro6D]: petrolNox6,
  [CarTech.Euro6ABC]: petrolNox6,
  [CarTech.Euro5]: new EmissionFactor(
    5,
    130,
    -0.000314541,
    0.103056777,
    0.239056576,
    -0.339279149,
    0.034535828,
    1.986012746,
    1.26376326,
    0
  ),
  [CarTech.Euro4]: new EmissionFactor(
    5,
    130,
    3.85567e-5,
    -0.008580222,
    0.577346262,
    1.30653e-12,
    2.70176e-17,
    -1.30767e-13,
    5.430520471,
    0
  ),
  [CarTech.Euro3]: new EmissionFactor(
    5,
    130,
    1.8325e-5,
    -0.004181358,
    0.260842825,
    6.56213e-12,
    0.000111409,
    -0.034236625,
    2.806280749,
    0
  ),
};

const hybrid6Nox = new EmissionFactor(
  20,
  130,
  -6.23693e-5,
  0.010848278,
  -0.16587581,
  -3.98242e-11,
  1.0002e-14,
  -2.04658e-12,
  16.58758101,
  0
);

const hybrid4Nox = new EmissionFactor(
  20,
  130,
  -6.23693e-5,
  0.010848278,
  -0.16587581,
  -3.98242e-11,
  1.0002e-14,
  -2.04658e-12,
  16.58758101,
  0
);

const hybridNox: CarEmissionMapping = {
  [CarTech.Euro6D]: hybrid6Nox,
  [CarTech.Euro6ABC]: hybrid6Nox,
  [CarTech.Euro5]: new EmissionFactor(
    20,
    130,
    -6.23693e-5,
    0.010848278,
    -0.16587581,
    -3.98242e-11,
    1.0002e-14,
    -2.04658e-12,
    16.58758101,
    0
  ),
  [CarTech.Euro4]: hybrid4Nox,
  [CarTech.Euro3]: hybrid4Nox,
};

export const noxEmissions: Record<CarType, CarEmissionMapping | null> = {
  [CarType.Diesel]: dieselNox,
  [CarType.Petrol]: petrolNox,
  [CarType.HybridPetrol]: hybridNox,
  [CarType.Electric]: null,
};

const dieselPm26 = new EmissionFactor(
  10,
  130,
  0.000467299,
  0.066409498,
  -0.325010593,
  0.960762635,
  1.137238656,
  0.546179555,
  0.289633152,
  0
);

const dieselPm2: CarEmissionMapping = {
  [CarTech.Euro6D]: dieselPm26,
  [CarTech.Euro6ABC]: dieselPm26,
  [CarTech.Euro5]: new EmissionFactor(
    10,
    130,
    -2.68933e-7,
    7.56612e-5,
    0.023884064,
    0.082659395,
    -0.000607959,
    0.211453856,
    4.455581988,
    0
  ),
  [CarTech.Euro4]: new EmissionFactor(
    10,
    130,
    1.17231e-5,
    -0.001815729,
    0.151591448,
    2.44305e-14,
    -4.45456e-18,
    -3.27965e-15,
    3.368698837,
    0
  ),
  [CarTech.Euro3]: new EmissionFactor(
    10,
    130,
    5.03411e-5,
    -0.005455682,
    0.319281393,
    -1.29996e-13,
    -3.07318e-16,
    2.97083e-14,
    6.199638692,
    0
  ),
};

const petrolPm2: CarEmissionMapping = {
  [CarTech.Euro6ABC]: new EmissionFactor(
    10,
    130,
    7.35732e-7,
    -0.000158132,
    0.009284705,
    0.04108643,
    0.000268768,
    -0.077925014,
    6.021053229,
    0
  ),
  [CarTech.Euro6D]: new EmissionFactor(
    10,
    130,
    5.59634e-7,
    -0.000116929,
    0.007459511,
    0.007401749,
    9.33277e-5,
    -0.034887675,
    3.505106497,
    0.5
  ),
  [CarTech.Euro5]: new EmissionFactor(
    10,
    130,
    5.7274e-7,
    -0.000125895,
    0.006907195,
    0.064229411,
    0.000329757,
    -0.087766409,
    6.011921291,
    0
  ),
  [CarTech.Euro4]: new EmissionFactor(10, 130, 0, 0, 0.00128, 0, 0, 0, 1, 0),
  [CarTech.Euro3]: new EmissionFactor(10, 130, 0, 0, 0.00128, 0, 0, 0, 1, 0),
};

const hybridPm42 = new EmissionFactor(10, 130, 0, 0, 0.00128, 0, 0, 0, 1, 0);

const hybridPm2: CarEmissionMapping = {
  [CarTech.Euro6ABC]: new EmissionFactor(
    10,
    130,
    7.35732e-7,
    -0.000158132,
    0.009284705,
    0.04108643,
    0.000268768,
    -0.077925014,
    6.021053229,
    0
  ),
  [CarTech.Euro6D]: new EmissionFactor(
    10,
    130,
    5.59634e-7,
    -0.000116929,
    0.007459511,
    0.007401749,
    9.33277e-5,
    -0.034887675,
    3.505106497,
    0.5
  ),
  [CarTech.Euro5]: new EmissionFactor(
    10,
    130,
    5.7274e-7,
    -0.000125895,
    0.006907195,
    0.064229411,
    0.000329757,
    -0.087766409,
    6.011921291,
    0
  ),
  [CarTech.Euro4]: hybridPm42,
  [CarTech.Euro3]: hybridPm42,
};

export const pm25Emissions: Record<CarType, CarEmissionMapping | null> = {
  [CarType.Diesel]: dieselPm2,
  [CarType.Petrol]: petrolPm2,
  [CarType.HybridPetrol]: hybridPm2,
  [CarType.Electric]: null,
};

export function carEmissionsByType(type: CarType, tech: CarTech, avgSpeed: number): EmFactors {
  return {
    nox: noxEmissions[type]?.[tech].calculate(avgSpeed) || 0,
    pm25: pm25Emissions[type]?.[tech].calculate(avgSpeed) || 0,
  };
}
