import styled from 'styled-components/macro';
import { useQuestion } from '../../CalcContext';
import { breakpointsMin } from '../../styles';

const InfoTitle = styled.h3`
  margin: 0 0 1.6rem 0;
  color: var(--apc-yellow);
`;

const ImageWrapper = styled.div`
  order: 2;
  max-width: 30rem;
  margin-top: 1.6rem;
`;

const BodyText = styled.div`
  order: 1;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .blurb-image {
    max-width: 40rem;
  }

  ${breakpointsMin.sm} {
    ${InfoTitle} {
      margin-bottom: 5.6rem;
    }

    ${ImageWrapper} {
      order: 1;
      margin: 0 auto 6rem auto;
    }

    ${BodyText} {
      order: 2;
    }
  }
`;

const QuizInfoContent = () => {
  const question = useQuestion();

  if (!question) {
    return null;
  }

  const { info } = question;

  return (
    <Wrapper>
      <InfoTitle>{info.title}</InfoTitle>

      {info.image && (
        <ImageWrapper>
          <info.image width="100%" height="auto" />
        </ImageWrapper>
      )}
      <BodyText>{info.body}</BodyText>
    </Wrapper>
  );
};

export default QuizInfoContent;
