import { carEmissionsByType, CarTech, CarType } from './calc/cars';
import { LocationFactors } from './calc/location';
import { bikeEmissionsByType, BikeTech, BikeType } from './calc/motorbike';
import { EmFactors } from './calc/types';

const busEmissions = {
  nox: 0.438612522,
  pm25: 0.005482657,
};

const WEEKS_PER_YEAR = 52;

export function calcEmissions(
  loc: LocationFactors,
  factors: EmFactors,
  hours: number,
  people: number,
  avgSpeed: number
) {
  const noxEmission = factors.nox * avgSpeed * hours * (WEEKS_PER_YEAR / people);
  const pm25Emission = factors.pm25 * avgSpeed * hours * (WEEKS_PER_YEAR / people);
  const noxCost = noxEmission * (loc.noxCost / 10 ** 6);
  const pm25Cost = pm25Emission * (loc.pm26Cost / 10 ** 6);

  return {
    noxEmission,
    noxCost,
    pm25Emission,
    pm25Cost,
  };
}

export function travelByCar(
  location: LocationFactors,
  type: CarType,
  tech: CarTech,
  hours: number,
  people: number
) {
  return calcEmissions(
    location,
    carEmissionsByType(type, tech, location.avgSpeed),
    hours,
    people + 1,
    location.avgSpeed
  );
}

export function travelByBus(location: LocationFactors, hours: number) {
  return calcEmissions(location, busEmissions, hours, 1, location.avgBusSpeed);
}

export function commutingByMotorbike(
  location: LocationFactors,
  type: BikeType,
  tech: BikeTech,
  hours: number
) {
  return calcEmissions(
    location,
    bikeEmissionsByType(type, tech, location.avgSpeed),
    hours,
    1,
    location.avgSpeed
  );
}
