import { TransportType } from '../questions';
import { ReactNode } from 'react';
import { CarTech, CarType } from '../calc/cars';
import { BikeTech, BikeType } from '../calc/motorbike';
import { ResidenceType } from '../calc/residence';
import { BoilerFuel, StoveType } from '../calc/heating';

export enum QuestionId {
  Q1Postcode = 1,
  Q2Commute = 2,
  Q3CarType = 3,
  Q4CarAge = 4,
  Q5CarHours = 5,
  Q6CarTravellers = 6,
  Q7BusHours = 7,
  Q8MotorbikeType = 8,
  Q9MotorbikeTech = 9,
  Q10MotorbikeHours = 10,
  Q11NonCommuteCar = 11,
  Q12NonCommuteCarType = 12,
  Q13NonCommuteCarAge = 13,
  Q14NonCommuteCarHours = 14,
  Q15NonCommuteCarTravellers = 15,
  Q16NonCommuteBus = 16,
  Q17NonCommuteBusHours = 17,
  Q18ResidenceSize = 18,
  Q19Cohabitants = 19,
  Q20WoodBurner = 20,
  Q21WoodBurnerType = 21,
  Q22WoodBurnerHours = 22,
  Q23GasBoiler = 23,
  Q24BoilerFuel = 24,
  Q25ElectricFuelPump = 25,
  Q26OnlinePurchases = 26,
  Q27OnlinePurchaseNum = 27,
  Q28OnlineGroceries = 28,
  Q29OnlineGroceryNum = 29,
  End = 30,
}

export interface FormState {
  [QuestionId.Q1Postcode]: string | null;
  [QuestionId.Q2Commute]: TransportType | null;
  [QuestionId.Q3CarType]: CarType | null;
  [QuestionId.Q4CarAge]: CarTech | null;
  [QuestionId.Q5CarHours]: number | null;
  [QuestionId.Q6CarTravellers]: number | null;
  [QuestionId.Q7BusHours]: number | null;
  [QuestionId.Q8MotorbikeType]: BikeType | null;
  [QuestionId.Q9MotorbikeTech]: BikeTech | null;
  [QuestionId.Q10MotorbikeHours]: number | null;
  [QuestionId.Q11NonCommuteCar]: boolean | null;
  [QuestionId.Q12NonCommuteCarType]: CarType | null;
  [QuestionId.Q13NonCommuteCarAge]: CarTech | null;
  [QuestionId.Q14NonCommuteCarHours]: number | null;
  [QuestionId.Q15NonCommuteCarTravellers]: number | null;
  [QuestionId.Q16NonCommuteBus]: boolean | null;
  [QuestionId.Q17NonCommuteBusHours]: number | null;
  [QuestionId.Q18ResidenceSize]: ResidenceType | null;
  [QuestionId.Q19Cohabitants]: number | null;
  [QuestionId.Q20WoodBurner]: boolean | null;
  [QuestionId.Q21WoodBurnerType]: StoveType | null;
  [QuestionId.Q22WoodBurnerHours]: [number, number] | null;
  [QuestionId.Q23GasBoiler]: boolean | null;
  [QuestionId.Q24BoilerFuel]: BoilerFuel | null;
  [QuestionId.Q25ElectricFuelPump]: boolean | null;
  [QuestionId.Q26OnlinePurchases]: boolean | null;
  [QuestionId.Q27OnlinePurchaseNum]: number | null;
  [QuestionId.Q28OnlineGroceries]: boolean | null;
  [QuestionId.Q29OnlineGroceryNum]: number | null;
  [QuestionId.End]: null;
}

export type FormFilter = (values: FormState) => boolean;

export interface Option {
  label: string;
  value: any;
}

export interface QuestionInfo {
  image?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  title?: string;
  body?: ReactNode;
}

interface QuestionBase {
  id: QuestionId;
  label: ReactNode;
  subLabel?: string;
  filter?: (values: FormState) => boolean;
  info: QuestionInfo;
  footnote?: ReactNode;
}

export interface OptionsQuestion extends QuestionBase {
  type: 'options';
  options: Option[];
}

export interface NumberQuestion extends QuestionBase {
  type: 'number';
}

export interface MessageQuestion extends QuestionBase {
  type: 'message';
}

export interface PostcodeQuestion extends QuestionBase {
  type: 'postcode';
}

export interface TwoNumberQuestion extends QuestionBase {
  type: 'twoNumber';
  subLabel1: string;
  subLabel2: string;
}

export type Question =
  | OptionsQuestion
  | NumberQuestion
  | MessageQuestion
  | PostcodeQuestion
  | TwoNumberQuestion;
