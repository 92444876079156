export const colors = {
  pink: '#EC1C99',
  red: '#F30043',
  orange: '#EC652C',
  yellow: '#FFCF0C',
  green: '#C2D52F',
  blue: '#00BAFD',
  navy: '#000079',
  grey: '#1D1D1D',
  lightGrey: '#666',
  white: '#FFF',
};

export type ColorName = keyof typeof colors;
