import { VFC } from 'react';
import styled from 'styled-components/macro';
import { CalcResults, ExtraPledge, QuizPledge, PledgeState } from '../../../calc/types';
import { ResultsSpacer } from '../Spacer';
import { ExtraPledgesGroup, PledgeGroup, PledgeGroupProps } from './PledgeGroup';

const PledgeSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .marker {
    align-self: center;
  }
`;

export interface PledgeSectionProps {
  results: CalcResults;
  pledges: PledgeState;
  onToggle: (pledge: QuizPledge | ExtraPledge) => void;
}

export const PledgeSection: VFC<PledgeSectionProps> = ({ results, pledges, onToggle }) => {
  const groups: PledgeGroupProps[] = [];
  const { breakdown, individual, target } = results;

  if (pledges.commuting.pledges.length > 0) {
    groups.push({
      index: 0,
      categoryName: 'Commuting',
      currentRelative: breakdown.commuting,
      currentValue: individual.commuting,
      imageSrc: '/images/cars.svg',
      pledges: pledges.commuting.pledges,
      possibleValue: pledges.commuting.withAll?.individual.commuting ?? 0,
      withActive: {
        target: individual.commuting * target,
        you: pledges.commuting.withActive?.individual.commuting ?? 0,
      },
      onToggle,
    });
  }

  if (pledges.delivery.pledges.length > 0) {
    groups.push({
      index: 0,
      categoryName: 'Delivery',
      currentRelative: breakdown.delivery,
      currentValue: individual.delivery,
      imageSrc: '/images/transport-methods.svg',
      pledges: pledges.delivery.pledges,
      possibleValue: pledges.delivery.withAll?.individual.delivery ?? 0,
      withActive: {
        target: individual.delivery * target,
        you: pledges.delivery.withActive?.individual.delivery ?? 0,
      },
      onToggle,
    });
  }

  if (pledges.domestic.pledges.length) {
    groups.push({
      index: 0,
      categoryName: 'Domestic energy',
      currentRelative: breakdown.domestic,
      currentValue: individual.domestic,
      imageSrc: '/images/fireplace.svg',
      pledges: pledges.domestic.pledges,
      possibleValue: pledges.domestic.withAll?.individual.domestic ?? 0,
      withActive: {
        target: individual.domestic * target,
        you: pledges.domestic.withActive?.individual.domestic ?? 0,
      },
      onToggle,
    });
  }

  if (pledges.leisure.pledges.length) {
    groups.push({
      index: 0,
      categoryName: 'Other travel',
      currentRelative: breakdown.leisure,
      currentValue: individual.leisure,
      imageSrc: '/images/bus.svg',
      pledges: pledges.leisure.pledges,
      possibleValue: pledges.leisure.withAll?.individual.leisure ?? 0,
      withActive: {
        target: individual.leisure * target,
        you: pledges.leisure.withActive?.individual.leisure ?? 0,
      },
      onToggle,
    });
  }

  groups.sort((a, b) => (b.currentRelative ?? 0) - (a.currentRelative ?? 0));

  return (
    <PledgeSectionWrapper>
      {groups.map((group, i) => (
        <>
          {i === 0 ? null : <ResultsSpacer />}
          <PledgeGroup {...group} index={i} />
        </>
      ))}
      <ResultsSpacer />
      <ExtraPledgesGroup pledges={pledges.extra} onToggle={onToggle} />
    </PledgeSectionWrapper>
  );
};
