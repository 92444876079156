import styled, { css } from 'styled-components';
import { breakpointsMin } from '.';

export const titleStyles = {
  md: css`
    font-family: 'VAG Rounded Next';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.4rem;
  `,
  lg: css`
    font-family: 'VAG Rounded Next';
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 2.8rem;
  `,
  xl: css`
    font-family: 'VAG Rounded Next';
    font-size: 4.8rem;
    font-weight: 500;
    line-height: 5.6rem;
  `,
};

export type TitleTextStyle = keyof typeof titleStyles;

export const bodyStyles = {
  sm: css`
    font-family: 'Epilogue';
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8rem;
  `,
  md: css`
    font-family: 'Epilogue';
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.2rem;
  `,
  lg: css`
    font-family: 'Epilogue';
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.4rem;
  `,
};

export type BodyTextStyle = keyof typeof bodyStyles;

export const TitleSm = styled.h3`
  ${bodyStyles.sm};
  color: var(--apc-yellow);
  margin-bottom: 0.8rem;
`;

export const TitleXl = styled.span`
  ${titleStyles.lg};

  ${breakpointsMin.md} {
    ${titleStyles.xl};
  }
`;
