import { useState, VFC } from 'react';
import styled from 'styled-components/macro';
import { useCalc } from '../../CalcContext';
import { ContentPage } from '../../components/ContentPage';
import { Crumbs } from '../../components/Crumbs';
import { breakpointsMin } from '../../styles';
import { Intro } from '../../styles/atoms';
import { bodyStyles, TitleXl } from '../../styles/text';

const Customise = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
`;

const MessageBox = styled.textarea`
  background: var(--apc-charcoal);
  padding: 2.4rem;
  ${bodyStyles.sm}
  color: var(--apc-white);
  width: 32.7rem;
  display: block;
  height: 30rem;
  border: 0;
  border-radius: 0.4rem;
  margin-bottom: 2.4rem;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpointsMin.md} {
    flex-direction: row;

    ${Customise} {
      margin-right: 4rem;
    }
  }
`;

const ShareButton = styled.a`
  width: 32.4rem;
  padding: 1.9rem;
  ${bodyStyles.lg};
  border-radius: 0.8rem;
  color: var(--apc-white);
  margin-bottom: 1.6rem;
  text-align: center;
`;

const TwitterButton = styled(ShareButton)`
  background: #1da1f1;
`;

const FacebookButton = styled(ShareButton)`
  background: #3e548e;
`;

const LinkedInButton = styled(ShareButton)`
  background: #0170ad;
`;

const WhatsAppButton = styled(ShareButton)`
  background: #3fbe4f;
`;

export const SharingButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const SectionTitle = styled.div`
  color: var(--apc-yellow);
  margin: 0 0 0.8rem 0;
`;

const SectionMessage = styled.div`
  color: var(--apc-cloud);
  margin: 0 0 2.4rem 0;
  ${bodyStyles.sm}
`;

const sharingUrl = encodeURIComponent('https://calculator.cleanairhub.org.uk/');

export const TakeAction: VFC = () => {
  const { pledges } = useCalc();

  const [message, setMessage] = useState(() => {
    const items = Array.from(
      new Set(
        [
          ...(pledges?.commuting.pledges ?? []),
          ...(pledges?.delivery.pledges ?? []),
          ...(pledges?.domestic.pledges ?? []),
          ...(pledges?.leisure.pledges ?? []),
          ...(pledges?.extra ?? []),
        ]
          .filter((p) => p.active)
          .map((p) => `- ${p.title}`)
      )
    );

    return `I pledge to…

${items.join('\n')}

Calculate your #airpollution footprint for #CleanAirDay

calculator.cleanairhub.org.uk`;
  });

  const sharingMessage = encodeURIComponent(message);

  return (
    <ContentPage>
      <Crumbs stage="action" />
      <TitleXl>Share your pledge</TitleXl>
      <Intro>Commit to changing your habits and share this on social media</Intro>

      <Layout>
        <Customise>
          <SectionTitle>Message</SectionTitle>
          <SectionMessage>You can edit your post if you like</SectionMessage>
          <MessageBox value={message} onChange={(e) => setMessage(e.currentTarget.value)} />
        </Customise>

        <SharingButtons>
          <TwitterButton
            href={`https://twitter.com/intent/tweet/?text=${sharingMessage}&amp;url=${sharingUrl}"`}
            target="_blank"
            rel="noopener"
            aria-label="Share on Twitter"
          >
            Share on Twitter
          </TwitterButton>

          <FacebookButton
            href={`https://facebook.com/sharer/sharer.php?u=${sharingUrl}&quote=${sharingMessage}`}
            target="_blank"
            rel="noopener"
            aria-label="Share on Facebook"
          >
            Share on Facebook
          </FacebookButton>

          <LinkedInButton
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${sharingUrl}&title=Air%20Pollution%20Calculator&summary=${sharingMessage}&source=${sharingUrl}`}
            target="_blank"
            rel="noopener"
            aria-label="Share on LinkedIn"
          >
            Share on LinkedIn
          </LinkedInButton>

          <WhatsAppButton
            href={`whatsapp://send?text=${sharingMessage}`}
            target="_blank"
            rel="noopener"
            aria-label="Share on WhatsApp"
          >
            Share on WhatsApp
          </WhatsAppButton>
        </SharingButtons>
      </Layout>
    </ContentPage>
  );
};
