export const breakpoints = {
  xxs: '375px',
  xs1: '376px',
  xs: '576px',
  sm: '768px',
  md: '1024px',
  lg: '1200px',
  xl: '2048px',
};

export const breakpointsMin = Object.fromEntries(
  Object.entries(breakpoints).map(([key, value]) => [key, `@media (min-width: ${value})`])
) as Record<keyof typeof breakpoints, string>;
