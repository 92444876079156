import React, { ReactNode } from 'react';
import { MultiChoiceWrapper, MultiChoiceLabel } from './MultiChoice.styles';
import { Option } from '../../pages/Quiz.types';
import { ToggleButton } from '../Button';

interface MultiChoiceProps {
  label: ReactNode;
  options: Option[];
  name: string;
  value: string;
  onChange?: (value: any) => void;
}

export const MultiChoice = ({ label, name, options, onChange, value }: MultiChoiceProps) => {
  return (
    <MultiChoiceWrapper>
      <MultiChoiceLabel>{label}</MultiChoiceLabel>
      {options.map((option) => (
        <ToggleButton
          active={option.value === value}
          onClick={() => {
            onChange && onChange(option.value);
          }}
        >
          {option.label}
        </ToggleButton>
      ))}
    </MultiChoiceWrapper>
  );
};
