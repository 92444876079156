import { EmFactors } from './types';
import { EmissionFactor } from './vehicles';

export enum BikeType {
  TwoStroke50 = 'TwoStroke50',
  TwoStroke50Plus = 'TwoStroke50Plus',
  FourStroke50 = 'FourStroke50',
  FourStroke250 = 'FourStroke250',
  FourStroke750 = 'FourStroke750',
  FourStroke750Plus = 'FourStroke750Plus',
  Unknown = 'Unknown',
}

export enum BikeTech {
  Euro5 = 'Euro5',
  Euro4 = 'Euro4',
  Euro3 = 'Euro3',
}

export type BikeEmissionMapping = Record<BikeTech, EmissionFactor>;

const noxEmissions: Record<BikeType, BikeEmissionMapping | null> = {
  [BikeType.TwoStroke50]: {
    [BikeTech.Euro3]: new EmissionFactor(10, 60, 0, 0, 0.393765174, 0, 0, 0, 1, 0),
    [BikeTech.Euro4]: new EmissionFactor(10, 60, 0, 0, 0.044349715, 0, 0, 0, 1, 0),
    [BikeTech.Euro5]: new EmissionFactor(10, 60, 0, 0, 0.044349715, 0, 0, 0, 1, 0),
  },
  [BikeType.TwoStroke50Plus]: {
    [BikeTech.Euro3]: new EmissionFactor(
      10,
      130,
      -0.000759082,
      0.028047645,
      -2.724697383,
      17.78168725,
      0.006958956,
      -1.86176439,
      10.26274902,
      0
    ),
    [BikeTech.Euro4]: new EmissionFactor(
      10,
      130,
      -452.263213,
      11622.70108,
      -94174.25763,
      230115.3872,
      -7006.391939,
      147012.4339,
      -771378.6557,
      0
    ),
    [BikeTech.Euro5]: new EmissionFactor(
      10,
      130,
      -5.859876895,
      151.9277809,
      -1273.86089,
      3276.924375,
      -136.2870621,
      2837.834692,
      -15122.93994,
      0
    ),
  },
  [BikeType.FourStroke50]: {
    [BikeTech.Euro3]: new EmissionFactor(10, 60, 0, 0, 0.393765174, 0, 0, 0, 1, 0),
    [BikeTech.Euro4]: new EmissionFactor(10, 60, 0, 0, 0.044349715, 0, 0, 0, 1, 0),
    [BikeTech.Euro5]: new EmissionFactor(10, 60, 0, 0, 0.044349715, 0, 0, 0, 1, 0),
  },
  [BikeType.FourStroke250]: {
    [BikeTech.Euro3]: new EmissionFactor(
      10,
      130,
      0.005789618,
      -0.170962693,
      22.22301018,
      -148.4669995,
      -0.050419237,
      12.36104823,
      -70.37767666,
      0
    ),
    [BikeTech.Euro4]: new EmissionFactor(
      10,
      130,
      0.394335709,
      -10.47236382,
      101.6622318,
      -320.4143493,
      10.13763886,
      -195.7581351,
      1095.25097,
      0
    ),
    [BikeTech.Euro5]: new EmissionFactor(
      10,
      130,
      0.042256027,
      -1.072157761,
      11.72774015,
      -44.36506427,
      1.545940303,
      -23.08242318,
      117.7123385,
      0
    ),
  },
  [BikeType.FourStroke750]: {
    [BikeTech.Euro3]: new EmissionFactor(
      10,
      130,
      0.000864632,
      0.000408762,
      1.85546668,
      -14.26644044,
      -0.003899432,
      1.700422672,
      -10.66277478,
      0
    ),
    [BikeTech.Euro4]: new EmissionFactor(
      10,
      130,
      0.000491089,
      -0.027615515,
      1.463162295,
      -9.674207701,
      -0.009353145,
      1.989673579,
      -11.98831822,
      0
    ),
    [BikeTech.Euro5]: new EmissionFactor(
      10,
      130,
      0.000405349,
      -0.022232269,
      1.195544422,
      -7.984412112,
      -0.011618199,
      2.485573416,
      -15.35885267,
      0
    ),
  },
  [BikeType.FourStroke750Plus]: {
    [BikeTech.Euro3]: new EmissionFactor(
      10,
      130,
      0.000277732,
      -0.316587225,
      2.6409421,
      39.96882619,
      0.034594568,
      -7.823845185,
      118.3266225,
      0
    ),
    [BikeTech.Euro4]: new EmissionFactor(
      10,
      130,
      -0.001818444,
      -0.017912047,
      -3.845056931,
      102.0257302,
      0.099923286,
      -20.02367261,
      369.2413637,
      0
    ),
    [BikeTech.Euro5]: new EmissionFactor(
      10,
      130,
      -0.000631341,
      0.000490383,
      -1.487386029,
      30.86934493,
      0.045559668,
      -9.160827749,
      153.0546364,
      0
    ),
  },
  [BikeType.Unknown]: null,
};

const pm25Emissions: Record<BikeType, BikeEmissionMapping | null> = {
  [BikeType.TwoStroke50]: {
    [BikeTech.Euro3]: new EmissionFactor(10, 110, 0, 0, 8.41860077, 0, 0, 0, 1000, 0),
    [BikeTech.Euro4]: new EmissionFactor(10, 110, 0, 0, 1.7555, 0, 0, 0, 1000, 0),
    [BikeTech.Euro5]: new EmissionFactor(10, 110, 0, 0, 1.7555, 0, 0, 0, 1000, 0),
  },
  [BikeType.TwoStroke50Plus]: {
    [BikeTech.Euro3]: new EmissionFactor(10, 110, 0, 0, 0.012, 0, 0, 0, 1, 0),
    [BikeTech.Euro4]: new EmissionFactor(10, 110, 0, 0, 0.012, 0, 0, 0, 1, 0),
    [BikeTech.Euro5]: new EmissionFactor(10, 110, 0, 0, 0.012, 0, 0, 0, 1, 0),
  },
  [BikeType.FourStroke50]: {
    [BikeTech.Euro3]: new EmissionFactor(10, 110, 0, 0, 8.41860077, 0, 0, 0, 1000, 0),
    [BikeTech.Euro4]: new EmissionFactor(10, 110, 0, 0, 1.7555, 0, 0, 0, 1000, 0),
    [BikeTech.Euro5]: new EmissionFactor(10, 110, 0, 0, 1.7555, 0, 0, 0, 1000, 0),
  },
  [BikeType.FourStroke250]: {
    [BikeTech.Euro3]: new EmissionFactor(10, 110, 0, 0, 0.005, 0, 0, 0, 1, 0),
    [BikeTech.Euro4]: new EmissionFactor(10, 110, 0, 0, 0.005, 0, 0, 0, 1, 0),
    [BikeTech.Euro5]: new EmissionFactor(10, 110, 0, 0, 0.005, 0, 0, 0, 1, 0),
  },
  [BikeType.FourStroke750]: {
    [BikeTech.Euro3]: new EmissionFactor(10, 110, 0, 0, 0.005, 0, 0, 0, 1, 0),
    [BikeTech.Euro4]: new EmissionFactor(10, 110, 0, 0, 0.005, 0, 0, 0, 1, 0),
    [BikeTech.Euro5]: new EmissionFactor(10, 110, 0, 0, 0.005, 0, 0, 0, 1, 0),
  },
  [BikeType.FourStroke750Plus]: {
    [BikeTech.Euro3]: new EmissionFactor(10, 110, 0, 0, 0.005, 0, 0, 0, 1, 0),
    [BikeTech.Euro4]: new EmissionFactor(10, 110, 0, 0, 0.005, 0, 0, 0, 1, 0),
    [BikeTech.Euro5]: new EmissionFactor(10, 110, 0, 0, 0.005, 0, 0, 0, 1, 0),
  },
  [BikeType.Unknown]: null,
};

export function bikeEmissionsByType(type: BikeType, tech: BikeTech, avgSpeed: number): EmFactors {
  return {
    nox: noxEmissions[type]?.[tech].calculate(avgSpeed) || 0.104,
    pm25: pm25Emissions[type]?.[tech].calculate(avgSpeed) || 0.0045,
  };
}
