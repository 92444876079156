import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { Colors } from '../../styles/colors';
import { useCalc } from '../../CalcContext';
import { QuestionId } from '../../pages/Quiz.types';
import { breakpointsMin } from '../../styles';

const PositionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  align-self: center;

  .words {
    display: none;
    margin-left: 1.2rem;
  }

  ${breakpointsMin.sm} {
    .words {
      display: inline;
    }
  }
`;

const Line = styled.div<{ pos: number }>`
  margin: 4px;
  width: 2.4rem;
  height: 4px;
  background-color: ${({ pos }) =>
    pos < 0 ? Colors.yellow : pos > 0 ? Colors.lightGrey : Colors.pink};
`;

const sections = [
  {
    name: 'Commuting',
    startId: QuestionId.Q1Postcode,
  },
  {
    name: 'Leisure travel',
    startId: QuestionId.Q11NonCommuteCar,
  },
  {
    name: 'Domestic energy',
    startId: QuestionId.Q18ResidenceSize,
  },
  {
    name: 'Delivery',
    startId: QuestionId.Q26OnlinePurchases,
  },
];

export const Position = memo(() => {
  const {
    state: { questionId },
  } = useCalc();

  return (
    <PositionWrapper>
      {sections.map((s) => (
        <Line key={`section-${s.startId}`} pos={s.startId - questionId} />
      ))}
    </PositionWrapper>
  );
});
