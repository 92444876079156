import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as GapLogo } from '../../images/gap-logo.svg';
import { ReactComponent as CahLogo } from '../../images/cah-logo.svg';
import { ReactComponent as ImpLogo } from '../../images/imp-logo.svg';
import { Position } from '../Position';

const QuizHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 3.2rem 0;

  .gap-logo {
    margin-left: 1.8rem;
  }
`;

const LogoLink = styled.a`
  &:last-child {
    margin-left: auto;
  }
`;

export const QuizHeader: FC<{ className?: string; kind: 'start' | 'questions' | 'results' }> = ({
  className,
  kind,
}) => {
  return (
    <QuizHeaderWrapper className={className}>
      <LogoLink href="/">
        <CahLogo aria-label="Clean Air Hub" className="logo" />
      </LogoLink>
      {kind === 'start' ? (
        <LogoLink href="https://www.globalactionplan.org.uk/" className="gap-logo">
          <GapLogo aria-label="Global Action Plan" className="logo" />
        </LogoLink>
      ) : null}
      {kind === 'questions' ? (
        <Position />
      ) : (
        <LogoLink href="https://www.imperial.ac.uk/">
          <ImpLogo aria-label="Imperial College London" className="logo" />
        </LogoLink>
      )}
    </QuizHeaderWrapper>
  );
};
