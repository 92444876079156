import { VFC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useCalc } from '../../CalcContext';
import { Button } from '../../components/Button';
import { QuizHeader } from '../../components/QuizHeader';
import { breakpointsMin } from '../../styles';
import { bodyStyles, titleStyles } from '../../styles/text';

const Title = styled.h1`
  margin: 12rem 0 3.2rem 0;
  ${titleStyles.xl};
`;

const Para = styled.p`
  margin: 1.6rem 0;
  ${bodyStyles.lg};
`;

const BigButton = styled(Button)`
  ${bodyStyles.lg};
  margin: 4.8rem 0 1.6rem 0;
  max-width: 34.6rem;
  display: block;
  padding: 2rem;
  width: 100%;
`;

const BigLink = styled(Link)`
  ${bodyStyles.md}
  display: block;
  text-align: center;

  ${breakpointsMin.sm} {
    ${bodyStyles.lg};
  }
`;

const Content = styled.div`
  max-width: 52.5rem;
`;

const LinkWrapper = styled.div`
  padding: 1.6rem;
  max-width: 34.6rem;
`;

const TextContent = styled.div`
  max-width: 44.5rem;
`;

export const Start: VFC = () => {
  const history = useHistory();
  const { reset } = useCalc();

  return (
    <>
      <QuizHeader kind="start" />
      <Content>
        <Title>Air Pollution Calculator</Title>
        <TextContent>
          <Para>
            Air pollution causes up to <b>36,000 deaths</b> in the UK every year. But this air pollution crisis is
            solvable.
          </Para>
          <Para>
            Calculate <b>how your activity contributes</b> to pollution levels and learn{' '}
            <b>how you can make a difference</b> for Clean Air Day 2022 and beyond.
          </Para>
        </TextContent>
        <BigButton
          onClick={() => {
            reset();
            history.push('/quiz');
          }}
        >
          Calculate my footprint
        </BigButton>
        <LinkWrapper>
          <BigLink to="/methodology">Learn more about this calculator</BigLink>
        </LinkWrapper>
      </Content>
    </>
  );
};
