import { travelByCar, travelByBus, commutingByMotorbike } from '../data';
import { FormState, QuestionId } from '../pages/Quiz.types';
import { TransportType } from '../questions';
import { deliveryEmissions } from './delivery';
import { stoveEmissions, gasBoilerEmissions } from './heating';
import { locationFactors } from './location';
import { residenceEmissions } from './residence';
import { CalcBreakdown, CalcResults, QuizPledge } from './types';

const AVG_COST_UK = 131;

const avgTravelCost = 23.14 + 7.44;

const averageCosts: CalcBreakdown = {
  domestic: 24.64 + 62.52,
  commuting: avgTravelCost / 3,
  leisure: (avgTravelCost / 3) * 2,
  delivery: 14.17 + 3.35,
};

const carPledge = 'Take fewer car trips per week';
const burnerPledge = 'Reduce my wood burner use';
const walkCyclePledge = 'Walk and cycle more';
const deliveryPledge = 'Bundle my online orders into fewer deliveries';

export function calcResults(answers: FormState): CalcResults | null {
  try {
    const postcode = answers[QuestionId.Q1Postcode]?.toUpperCase();
    const location = postcode ? locationFactors(postcode) : null;
    const pledges = new Set<string>([]);

    console.debug(`Location: ${JSON.stringify(location)}`);

    if (location === null) {
      return null;
    }

    let commute = {
      noxEmission: 0,
      noxCost: 0,
      pm25Emission: 0,
      pm25Cost: 0,
    };

    let domestic = {
      noxEmission: 0,
      noxCost: 0,
      pm25Emission: 0,
      pm25Cost: 0,
    };

    let leisure = {
      noxEmission: 0,
      noxCost: 0,
      pm25Emission: 0,
      pm25Cost: 0,
    };

    let delivery = {
      noxEmission: 0,
      noxCost: 0,
      pm25Emission: 0,
      pm25Cost: 0,
    };

    const commuteType = answers[QuestionId.Q2Commute];

    if (!commuteType) {
      return null;
    }

    if (commuteType === TransportType.Car) {
      const carType = answers[QuestionId.Q3CarType];
      const carAge = answers[QuestionId.Q4CarAge];
      const carHours = answers[QuestionId.Q5CarHours];
      const carTravellers = answers[QuestionId.Q6CarTravellers];

      if (carType === null || carAge === null || carHours === null || carTravellers === null) {
        return null;
      }

      commute = travelByCar(location, carType, carAge, carHours, carTravellers);
      console.debug(`Commute by car: ${JSON.stringify(commute)}`);
      pledges.add(carPledge);
      pledges.add(walkCyclePledge);
    } else if (commuteType === TransportType.Bus) {
      const busHours = answers[QuestionId.Q7BusHours];

      if (busHours === null) {
        return null;
      }

      commute = travelByBus(location, busHours);
      pledges.add(walkCyclePledge);
      console.debug(`Commute by bus: ${commute}`);
    } else if (commuteType === TransportType.Motorbike) {
      const bikeType = answers[QuestionId.Q8MotorbikeType];
      const bikeHours = answers[QuestionId.Q10MotorbikeHours];
      const bikeTech = answers[QuestionId.Q9MotorbikeTech];

      if (bikeType === null || bikeHours === null || bikeTech === null) {
        return null;
      }

      commute = commutingByMotorbike(location, bikeType, bikeTech, bikeHours);
      pledges.add(walkCyclePledge);
      console.debug(`Commute by bike: ${JSON.stringify(commute)}`);
    }

    const nonCommuteCar = answers[QuestionId.Q11NonCommuteCar];

    if (nonCommuteCar === null) {
      return null;
    } else if (nonCommuteCar) {
      const carType = answers[QuestionId.Q12NonCommuteCarType];
      const carAge = answers[QuestionId.Q13NonCommuteCarAge];
      const carHours = answers[QuestionId.Q14NonCommuteCarHours];
      const carTravellers = answers[QuestionId.Q15NonCommuteCarTravellers];

      if (carType === null || carAge === null || carHours === null || carTravellers === null) {
        return null;
      }

      pledges.add(carPledge);
      pledges.add(walkCyclePledge);
      leisure = travelByCar(location, carType, carAge, carHours, carTravellers);
      console.debug(`Leisure by car: ${JSON.stringify(leisure)}`);
    }

    const nonCommuteBus = answers[QuestionId.Q16NonCommuteBus];

    if (nonCommuteBus === null) {
      return null;
    } else if (nonCommuteBus) {
      const busHours = answers[QuestionId.Q17NonCommuteBusHours];

      if (busHours === null) {
        return null;
      }

      const leisureBus = travelByBus(location, busHours);

      console.debug(`Bus leisure: ${JSON.stringify(leisureBus)}`);

      leisure.noxCost += leisureBus.noxCost;
      leisure.noxEmission += leisureBus.noxEmission;
      leisure.pm25Cost += leisureBus.pm25Cost;
      leisure.pm25Emission += leisureBus.pm25Emission;

      pledges.add(walkCyclePledge);
    }

    const residenceType = answers[QuestionId.Q18ResidenceSize];
    const cohabitants = answers[QuestionId.Q19Cohabitants];

    if (residenceType === null || cohabitants === null) {
      return null;
    } else {
      const residence = residenceEmissions(residenceType, cohabitants);

      console.debug(`Residence: ${JSON.stringify(residence)}`);

      domestic.noxCost += residence.cost.nox;
      domestic.pm25Cost += residence.cost.pm25;
      domestic.noxEmission += residence.emissions.nox;
      domestic.pm25Emission += residence.emissions.pm25;
    }

    const woodburner = answers[QuestionId.Q20WoodBurner];

    if (woodburner === null) {
      return null;
    } else if (woodburner) {
      const woodburnerType = answers[QuestionId.Q21WoodBurnerType];
      const hours = answers[QuestionId.Q22WoodBurnerHours];

      if (woodburnerType === null || cohabitants === null || hours === null) {
        return null;
      }

      const impact = stoveEmissions(woodburnerType, cohabitants, hours);

      domestic.noxCost += impact.noxCost;
      domestic.pm25Cost += impact.pm25Cost;
      domestic.noxEmission += impact.noxEmission;
      domestic.pm25Emission += impact.pm25Emission;

      console.debug(`Stove emissions: ${JSON.stringify(domestic)}`);
      pledges.add(burnerPledge);
    }

    const gasBurner = answers[QuestionId.Q23GasBoiler];

    if (gasBurner === null) {
      return null;
    } else if (gasBurner) {
      const boilerFuel = answers[QuestionId.Q24BoilerFuel];

      if (boilerFuel === null) {
        return null;
      }

      const impact = gasBoilerEmissions(boilerFuel, residenceType, cohabitants);

      console.debug(`Gas burner emissions: ${JSON.stringify(impact)}`);

      domestic.noxCost += impact.cost.nox;
      domestic.pm25Cost += impact.cost.pm25;
      domestic.noxEmission += impact.emissions.nox;
      domestic.pm25Emission += impact.emissions.pm25;
    }

    const onlineDelivery = answers[QuestionId.Q26OnlinePurchases];
    const onlineGrocery = answers[QuestionId.Q28OnlineGroceries];

    if (onlineDelivery === null || onlineGrocery === null) {
      return null;
    } else {
      const parcels = onlineDelivery ? answers[QuestionId.Q27OnlinePurchaseNum] || 0 : 0;
      const groceries = onlineGrocery ? answers[QuestionId.Q29OnlineGroceryNum] || 0 : 0;

      const impact = deliveryEmissions(parcels, groceries);

      console.debug(`Delivery emissions: ${JSON.stringify(impact)}`);

      delivery.noxCost += impact.cost.nox;
      delivery.pm25Cost += impact.cost.pm25;
      delivery.noxEmission += impact.emissions.nox;
      delivery.pm25Emission += impact.emissions.pm25;
      pledges.add(deliveryPledge);
    }

    const totalCost =
      commute.pm25Cost +
      commute.noxCost +
      leisure.pm25Cost +
      leisure.noxCost +
      domestic.pm25Cost +
      domestic.noxCost +
      delivery.noxCost +
      delivery.pm25Cost;

    console.debug(`Total cost: ${totalCost}`);

    const factors = {
      domestic: domestic.noxCost + domestic.pm25Cost,
      commuting: commute.noxCost + commute.pm25Cost,
      leisure: leisure.noxCost + leisure.pm25Cost,
      delivery: delivery.noxCost + delivery.pm25Cost,
    };

    const individual = {
      domestic: Math.round((factors.domestic / averageCosts.domestic) * 100),
      commuting: Math.round((factors.commuting / averageCosts.commuting) * 100),
      leisure: Math.round((factors.leisure / averageCosts.leisure) * 100),
      delivery: Math.round((factors.delivery / averageCosts.delivery) * 100),
    };

    const footprint = Math.round((totalCost / AVG_COST_UK) * 100);

    console.debug(`Footprint: ${footprint}`);

    if (pledges.size === 0) {
      pledges.add('Help raise awareness about air pollution');
    }

    return {
      footprint,
      breakdown: {
        domestic: factors.domestic / totalCost,
        commuting: factors.commuting / totalCost,
        leisure: factors.leisure / totalCost,
        delivery: factors.delivery / totalCost,
      },
      individual,
      target: footprint < 50 ? 0 : footprint > 100 ? 0.5 : 0.75,
    };
  } catch (error) {
    console.error(error);
  }

  return null;
}
