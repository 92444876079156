import styled, { createGlobalStyle } from 'styled-components/macro';
import { breakpointsMin } from './styles';
import { bodyStyles, titleStyles } from './styles/text';

export const AppWrapper = styled.div`
  margin: 0 auto;
  min-height: 100vh;
  max-width: 128rem;
  display: flex;
  flex-direction: column;
  padding: 0 2.4rem 0;
  overflow-x: hidden;

  ${breakpointsMin.md} {
    padding: 0 8rem;
  }
`;

export const AppContent = styled.div`
  flex: 1;
`;

export const GlobalStyles = createGlobalStyle`
  html {
    margin: 0;
    min-height: 100vh;
    font-size: 62.5%;

    --apc-yellow: #FFCF0C;
    --apc-pink: #EC1C99;
    --apc-carbon: #3D3F42;
    --apc-charcoal: #313235;
    --apc-cloud: #D0CFCF;
    --apc-white: #FFF;
    --apc-black: #000;
    --apc-lead: #505255;
    --apc-slate: #A7A7A7;
  }

  body {
    margin: 0;
    min-height: 100vh;
    background: radial-gradient(800px at bottom -300px right 23%, rgba(255,255,255,0.3) 0%,rgba(255,255,255,0) 100%), var(--apc-carbon);
    color: var(--apc-white);
    font-family: 'Epilogue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
     ${bodyStyles.md};
  }

  #root {
    min-height: 100vh;
  }

  a {
    color: var(--apc-yellow);
    text-decoration: none;
  }

  p {
    margin: 0;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  h1,  h2, h3 {
    margin: 0;
  }

  h1 {
    ${titleStyles.xl}
  }

  h2 {
    ${titleStyles.lg}
  }

  h3 {
    ${titleStyles.md}
  }
`;
