import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { bodyStyles } from '../../styles/text';

const buttonStyles = css`
  ${bodyStyles.md};
  display: inline-block;
  border: 0;
  background-color: var(--apc-yellow);
  border-radius: 0.8rem;
  padding: 1.6rem 2.4rem;
  color: var(--apc-black);
  cursor: pointer;
  text-align: center;

  &:disabled {
    background-color: #666;
  }

  &[hidden] {
    display: none;
  }
`;

export const Button = styled.button.attrs({ type: 'button' })`
  ${buttonStyles}
`;

export const SubmitButton = styled.button.attrs({ type: 'submit' })`
  ${buttonStyles}
`;

export const NavButton = styled(Link)<{ disabled: boolean }>`
  ${buttonStyles}

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : null}
`;

export const LinkButton = styled.button.attrs({ type: 'button' })`
  font-size: inherit;
  display: inline;
  background-color: transparent;
  border: 0;
  color: var(--apc-yellow);
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

export const TextButton = styled.button.attrs({ type: 'button' })`
  ${bodyStyles.sm};
  border: 0;
  background-color: transparent;
  display: inline-block;
  padding: 0;
  margin: 0;
  color: var(--apc-yellow);
  cursor: pointer;

  &[hidden] {
    display: none;
  }
`;

export const ToggleButton = styled.button.attrs({ type: 'button' })<{ active: boolean }>`
  ${buttonStyles};

  ${({ active }) =>
    active
      ? css``
      : css`
          background-color: transparent;
          border: 2px solid rgba(255, 255, 255, 0.05);
          color: var(--apc-white);
        `};
`;

export const DownloadButton = styled.a`
  ${bodyStyles.lg};
  border: 2px solid var(--apc-yellow);
  color: var(--apc-white);
  padding: 1.6rem 4.8rem;
  border-radius: 0.8rem;
  background: transparent;
  cursor: pointer;
  text-align: center;
`;
