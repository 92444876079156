import styled from 'styled-components/macro';
import { bodyStyles, titleStyles } from '../../styles/text';

export const NumberLabel = styled.label`
  margin-bottom: 2.8rem;
  ${titleStyles.md};
`;

export const NumberInput = styled.input.attrs({
  type: 'number',
})`
  ${bodyStyles.lg};
  border: 2px solid rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  background-color: transparent;
  padding: 1.2rem 1.6rem;
  color: inherit;
  width: 100%;
  margin: 0 0.4rem;

  &:focus {
    outline: none;
  }
`;

export const NumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  &.secondary {
    ${NumberLabel} {
      ${bodyStyles.md}
      margin: 1.6rem 0;
    }
  }
`;
