import { VFC } from 'react';
import styled, { css } from 'styled-components/macro';
import { ExtraPledge, QuizPledge } from '../../../calc/types';
import { useCalc } from '../../../CalcContext';
import { bodyStyles } from '../../../styles/text';

const Checkbox = styled.div`
  height: 2.1rem;
  width: 2.1rem;
  border: 2px solid var(--apc-white);
  border-radius: 0.4rem;
  display: grid;
  place-items: center;
  flex-shrink: 0;
`;

const Radio = styled.div`
  height: 2.2rem;
  width: 2.2rem;
  border: 2px solid var(--apc-white);
  border-radius: 1.1rem;
  display: grid;
  place-items: center;
  flex-shrink: 0;
`;

const RadioIndicator = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  background: var(--apc-yellow);
`;

const Copy = styled.div`
  color: var(--apc-white);
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.4rem;

  .title {
    ${bodyStyles.md}
    font-weight: bold;
  }

  .description {
    ${bodyStyles.sm}
  }
`;

const PledgeWrapper = styled.button<{ pressed?: boolean }>`
  background: transparent;
  border: 2px solid var(--apc-lead);
  border-radius: 0.8rem;
  display: flex;
  padding: 1.6rem;
  gap: 1.6rem;
  align-items: center;
  cursor: pointer;

  ${({ pressed }) =>
    pressed
      ? css`
          border-color: var(--apc-yellow);
          ${Checkbox} {
            background: var(--apc-yellow);
            border: 0;
          }
        `
      : css`
          &:hover {
            border-color: var(--apc-slate);
          }
        `}
`;

export interface PledgeProps {
  pledge: QuizPledge | ExtraPledge;
  onClick?: () => void;
}

export const PledgeButton: VFC<PledgeProps> = ({ pledge: { title, description, active, pledgeGroup }, onClick }) => {
  const { pledges } = useCalc();

  return (
    <PledgeWrapper aria-pressed={active} pressed={active} onClick={() => onClick?.()}>
      {pledgeGroup && (pledges?.groupCount.get(pledgeGroup) ?? 0) > 1 ? (
        <Radio>{active && <RadioIndicator />}</Radio>
      ) : (
        <Checkbox>{active && <img src="/images/check.svg" alt="tick" />}</Checkbox>
      )}
      <Copy>
        <div className="title">{title}</div>
        {description && <div className="description">{description}</div>}
      </Copy>
    </PledgeWrapper>
  );
};
