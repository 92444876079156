import { FC } from 'react';
import styled from 'styled-components/macro';
import { useCalc } from '../../CalcContext';
import { Redirect } from 'react-router-dom';
import { ContentPage } from '../../components/ContentPage';
import { breakpointsMin } from '../../styles';
import { DownloadButton, NavButton } from '../../components/Button';
import { Description } from './Description';
import { Comparison } from './Comparison';
import { titleStyles, TitleXl } from '../../styles/text';
import { Crumbs } from '../../components/Crumbs';
import Factors from './Factors';
import { PledgeSection } from './pledges/PledgeSection';
import { ResultsSpacer } from './Spacer';
import { ShareFeedback } from './ShareFeedback';

const ResultsWrapper = styled.div`
  margin: 0 auto;
`;

const Headline = styled.div`
  display: flex;
  margin-bottom: 10rem;
  flex-direction: column;

  ${breakpointsMin.sm} {
    flex-direction: row;
  }

  ${breakpointsMin.md} {
    flex-direction: row;

    .comparison {
      flex: 1;
      margin: 0 10rem 0 20rem;
    }

    .desc {
      flex: 3;
    }
  }
`;

const StyledFactors = styled(Factors)`
  margin-bottom: 25.6rem;
`;

const CommitSection = styled.div`
  display: grid;

  grid-template: 'message' auto 'panel' auto / 1fr;
  gap: 2.4rem;

  ${breakpointsMin.md} {
    grid-template: 'message panel' auto / 1fr 48rem;
    gap: 7.6rem;
  }
`;

const CommitMessage = styled.div`
  grid-area: message;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const CommitPanel = styled.div`
  grid-area: panel;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 4rem 2.4rem;
  background: var(--apc-white);
  color: var(--apc-charcoal);
  border-radius: 1.6rem;
  align-self: start;
  ${titleStyles.lg}
`;

const PdfButton = styled(DownloadButton)`
  align-self: start;
`;

export const Results: FC = () => {
  const {
    togglePledge,
    pledges,
    results,
    state: { activePledges },
  } = useCalc();

  if (!results) {
    return <Redirect to="/quiz" />;
  }

  const breakdown = results.breakdown;

  const noPledges = Object.keys(activePledges).length === 0;

  const pledgeDiff = (pledges?.results?.footprint ?? 0) - 100;

  return (
    <ContentPage>
      <ResultsWrapper>
        <Crumbs stage="results" />
        <Headline>
          <Description className="desc" count={results.footprint || 0} target={results.target}></Description>
          <Comparison className="comparison" score={results.footprint || 0} target={results.target} />
        </Headline>

        {results.footprint > 0 && pledges ? (
          <>
            <StyledFactors breakdown={breakdown} />
            <PledgeSection results={results} pledges={pledges} onToggle={(pledge) => togglePledge(pledge)} />
          </>
        ) : null}
        <ResultsSpacer />
        <CommitSection>
          <CommitMessage>
            <TitleXl>
              If you commit to your clean air pledges, your air pollution impact could be {Math.abs(pledgeDiff)}%
              {pledgeDiff > 0 ? ' above' : ' below'} the national average.
            </TitleXl>
            {pledgeDiff > 0 ? (
              <p>
                Your air pollution footprint is still higher than national average - consider taking another pledge to
                lower this!
              </p>
            ) : (
              <p>Commit to changing your habits and routines and share your pledge on social media.</p>
            )}
            <p>
              Use your personalized results from the Air Pollution Calculator to tick your pledges off this downloadable
              list and save it as a reminder to help you stick to them.
            </p>
            <PdfButton target="_blank" href="/clean-air-actions.pdf">
              Download Full Action List
            </PdfButton>
          </CommitMessage>
          <CommitPanel>
            🤞 Commit to pledges above to reduce your air pollution impact
            <NavButton className="next" to="/take-action" disabled={noPledges}>
              Next: Share your pledges
            </NavButton>
          </CommitPanel>
        </CommitSection>
        <ShareFeedback />
      </ResultsWrapper>
    </ContentPage>
  );
};
