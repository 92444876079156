import React, { FC, KeyboardEvent, useCallback, ReactNode } from 'react';
import styled from 'styled-components';
import { bodyStyles } from '../../styles/text';
import { Button } from '../Button';
import { NumberWrapper, NumberLabel, NumberInput } from './NumberField.styles';

interface NumberFieldProps {
  label: ReactNode;
  className?: string;
  onChange?: (value: number) => void;
  onEnter?: () => void;
  value: number;
}

const SpinButton = styled(Button)`
  ${bodyStyles.lg};
  font-size: 3.6rem;
  padding: 0;
  width: 5.6rem;
  flex-shrink: 0;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: stretch;
`;

export const NumberField: FC<NumberFieldProps> = ({
  label,
  onEnter,
  value,
  className,
  onChange,
}) => {
  const onKeyPressed = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && onEnter) {
        onEnter();
      }
    },
    [onEnter]
  );

  const ref = useCallback((node: HTMLInputElement | null) => {
    if (node) {
      node.focus();
    }
  }, []);

  return (
    <NumberWrapper className={className}>
      <NumberLabel>{label}</NumberLabel>
      <InputWrapper>
        <SpinButton onClick={() => onChange && onChange(Math.max(0, value - 1))}>-</SpinButton>
        <NumberInput
          onKeyPress={onKeyPressed}
          value={`${value}`}
          onChange={(e) => onChange && onChange(e.currentTarget.valueAsNumber)}
          ref={ref}
          min={0}
        />
        <SpinButton onClick={() => onChange && onChange(value + 1)}>+</SpinButton>
      </InputWrapper>
    </NumberWrapper>
  );
};
