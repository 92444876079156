import styled from 'styled-components/macro';
import { breakpoints, breakpointsMin } from '../styles';

export const QuizQuestion = styled.div`
  width: 44.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
`;

export const QuizInfo = styled.div`
  max-width: 53.3rem;
  background-color: var(--apc-charcoal);
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.5;
  padding: 2.4rem;
  border-radius: 0.8rem;

  ${breakpointsMin.sm} {
    padding: 6.4rem;
  }
`;

export const QuizWrapper = styled.div`
  display: flex;
  min-height: 100%;
  justify-content: space-between;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    height: auto;

    ${QuizQuestion} {
      width: 100%;
    }

    ${QuizInfo} {
      width: 100%;
    }
  }
`;
