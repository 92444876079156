import styled from 'styled-components/macro';
import { ToggleButton } from '../Button';

export const MultiChoiceLabel = styled.h3`
  margin: 0 0 2.4rem 0;
`;

export const MultiChoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${ToggleButton}:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;
