import React, { FC, useState, useRef, MouseEvent, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { MultiChoice } from '../MultiChoice';
import { QuizFormWrapper, NextButton } from './QuizForm.styles';
import { QuestionId, Question, FormState } from '../../pages/Quiz.types';
import { questions, questionsById } from '../../questions';
import { NumberField } from '../NumberField';
import { useCalc } from '../../CalcContext';
import { LinkButton } from '../Button';
import { TextField } from '../TextField';
import { NumberLabel } from '../NumberField/NumberField.styles';
import postcodes from '../../calc/postcodes.json';

const resultsLink = `/results`;

function getNextQuestionId(question: Question, values: FormState): QuestionId | null {
  let nextId = question.id;
  let nextQuestion: Question;

  if (values[question.id] === null) {
    return null;
  }

  do {
    nextId += 1;
    nextQuestion = questionsById.get(nextId)!;

    if (!nextQuestion) {
      return QuestionId.End;
    }
  } while (nextQuestion.filter && !nextQuestion.filter(values));

  return nextId;
}

const AutoMove: FC<{
  question: Question;
  values: FormState;
  setQuestionId: (id: QuestionId) => void;
}> = ({ question, values, setQuestionId }) => {
  const [oldValues, setOldValues] = useState<FormState>(values);

  if (oldValues !== values) {
    if (
      question.type !== 'number' &&
      question.type !== 'postcode' &&
      question.type !== 'twoNumber'
    ) {
      const nextId = getNextQuestionId(question, values);
      if (nextId && nextId !== QuestionId.End) {
        setTimeout(() => {
          setQuestionId(nextId);
        }, 100);
      }
    }

    setOldValues(values);
  }

  return null;
};

export const QuizForm: FC<{ className?: string }> = ({ className }) => {
  const {
    state: { answers, questionId, validPostcode },
    setAnswers,
    setQuestion,
  } = useCalc();

  const [previousQuestion, setPreviousQuestion] = useState<Question>(questions[0]);
  const forward = useRef(true);
  const history = useHistory();

  const question = questionsById.get(questionId)!;

  if (question !== previousQuestion) {
    forward.current = question.id > previousQuestion.id;
    setPreviousQuestion(question);
  }

  if (!question) {
    return null;
  }

  const prevQuestion = (e: MouseEvent<any, any>) => {
    e.preventDefault();

    let prevId = questionId;
    let prevQuestion: Question;

    do {
      prevId -= 1;
      prevQuestion = questionsById.get(prevId)!;
    } while (prevQuestion.filter && !prevQuestion.filter(answers));

    setQuestion(prevId);
  };

  const nextId = getNextQuestionId(question, answers);

  const nextQuestion = (e: React.MouseEvent<any, any>) => {
    e && e.preventDefault();

    if (nextId) {
      if (nextId === QuestionId.End) {
        history.push(resultsLink);
      }

      setQuestion(nextId);
    }
  };

  const onNumEnter = () => {
    if (nextId) {
      setQuestion(nextId);
    }
  };

  let questionContent: ReactNode;

  switch (question.type) {
    case 'number': {
      const value = answers[questionId];
      const valid = typeof value === 'number' && !Number.isNaN(value);

      questionContent = (
        <>
          <NumberField
            label={question.label}
            value={value as number}
            onChange={(value) => {
              setAnswers({
                ...answers,
                [questionId]: value,
              });
            }}
            onEnter={onNumEnter}
          />
          {nextId !== QuestionId.End ? (
            <NextButton onClick={nextQuestion} disabled={!valid}>
              Next question →
            </NextButton>
          ) : null}
        </>
      );
      break;
    }
    case 'options': {
      questionContent = (
        <MultiChoice
          label={question.label}
          options={question.options}
          name={`${questionId}`}
          value={answers[questionId] as any}
          onChange={(value: any) => {
            setAnswers({
              ...answers,
              [questionId]: value,
            });
          }}
        />
      );
      break;
    }
    case 'message': {
      questionContent = (
        <>
          {question.label}
          <NextButton onClick={nextQuestion}>Start the calculator</NextButton>
        </>
      );
      break;
    }
    case 'postcode': {
      const value = answers[questionId];

      questionContent = (
        <>
          <TextField
            label={question.label}
            value={`${value}`}
            onChange={(e) => {
              setAnswers({
                ...answers,
                [questionId]: e.target.value,
              });
            }}
            onEnter={() => {
              validPostcode && onNumEnter();
            }}
            list="postcodes-list"
          />
          <datalist id="postcodes-list">
            {(postcodes as Array<[string]>).map(([p]) => (
              <option key={`postcode-${p}`} value={p} />
            ))}
          </datalist>
          {nextId !== QuestionId.End ? (
            <NextButton onClick={nextQuestion} disabled={!validPostcode}>
              Next question →
            </NextButton>
          ) : null}
        </>
      );
      break;
    }
    case 'twoNumber': {
      const value = answers[questionId];

      let val1 = 0;
      let val2 = 0;

      if (Array.isArray(value)) {
        val1 = value[0];
        val2 = value[1];
      }

      const valid =
        typeof val1 === 'number' &&
        !Number.isNaN(val1) &&
        typeof val2 === 'number' &&
        !Number.isNaN(val2);

      questionContent = (
        <>
          <NumberLabel>{question.label}</NumberLabel>
          <NumberField
            label={question.subLabel1}
            value={val1 as number}
            className="secondary"
            onChange={(value) => {
              setAnswers({
                ...answers,
                [questionId]: [value, val2],
              });
            }}
            onEnter={onNumEnter}
          />
          <NumberField
            label={question.subLabel2}
            value={val2 as number}
            className="secondary"
            onChange={(value) => {
              setAnswers({
                ...answers,
                [questionId]: [val1, value],
              });
            }}
            onEnter={onNumEnter}
          />
          {nextId !== QuestionId.End ? (
            <NextButton onClick={nextQuestion} disabled={!valid}>
              Next question →
            </NextButton>
          ) : null}
        </>
      );
    }
  }

  return (
    <QuizFormWrapper
      className={className}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {questionContent}
      {nextId === QuestionId.End ? (
        <NextButton onClick={nextQuestion}>Find out my air pollution footprint</NextButton>
      ) : null}
      {questionId > 1 && (
        <LinkButton onClick={prevQuestion} className="prev">
          ← Back
        </LinkButton>
      )}
      <AutoMove question={question} values={answers} setQuestionId={setQuestion} />
    </QuizFormWrapper>
  );
};
