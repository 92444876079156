import { VFC } from 'react';
import styled from 'styled-components/macro';
import { CalcBreakdown } from '../../calc/types';
import { usePie } from './usePie';
import { PieChart } from 'react-minimal-pie-chart';
import { bodyStyles, TitleSm, TitleXl } from '../../styles/text';
import { breakpointsMin } from '../../styles';

const Wrapper = styled.div`
  background: var(--apc-charcoal);
  border-radius: 0.8rem;

  ${breakpointsMin.md} {
    padding: 4.8rem 8rem;
    display: grid;
    grid-template: 'chart copy' auto / auto 1fr;
    gap: 5.6rem;
    align-items: center;
  }
`;

const Chart = styled.div`
  grid-area: chart;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Copy = styled.div`
  grid-area: copy;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  p {
    ${bodyStyles.lg}
  }
`;

const PieContainer = styled.div`
  height: 20rem;
  width: 20rem;
  margin-bottom: 2rem;
`;

const Legend = styled.table`
  padding: 0;
  max-width: 27.8rem;
`;

const LegendItem = styled.tr`
  td {
    padding: 0.4rem 0;
  }
`;

const LegendTitle = styled.div<{ color: string }>`
  display: flex;
  align-items: center;

  &::before {
    margin-right: 1.2rem;
    display: inline-block;
    content: '';
    background-color: ${({ color }) => color};
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    flex-shrink: 0;
  }
`;

export interface FactorsProps {
  breakdown: CalcBreakdown;
  className?: string;
}

const Factors: VFC<FactorsProps> = ({ breakdown, className }) => {
  const pieData = usePie(breakdown);

  return (
    <Wrapper className={className}>
      <Chart>
        <TitleSm>Your air pollution footprint per category</TitleSm>
        <PieContainer>
          <PieChart className="pie" radius={45} data={pieData} lineWidth={24} />
        </PieContainer>
        <Legend>
          <tbody>
            {pieData.map((d) => (
              <LegendItem key={`legend-${d.title}`}>
                <td>
                  <LegendTitle color={d.color}>{d.title}</LegendTitle>
                </td>
                <td>{d.value}%</td>
              </LegendItem>
            ))}
          </tbody>
        </Legend>
      </Chart>
      <Copy>
        <TitleXl>So, how can I reduce my air pollution footprint?</TitleXl>
        <p>
          Our air pollution crisis is solvable and there are simple steps we can all take to avoid toxic air and cut
          down on our emissions — good for us and good for the planet. See what your biggest contributors are below, and
          make a pledge for change!
        </p>
      </Copy>
    </Wrapper>
  );
};

export default Factors;
